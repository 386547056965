import APIService from '../../../services/api';
import { FETCH_COMPLETE, FETCH_ERROR } from '../../vip/types';

const execFetchComponents = (dispatch, args, setIsLoading) => {
  const { id, params, app, vip_filters, variation_id } = args;
  setIsLoading(true);
  APIService.getItem({ ...params, id, app, vip_filters, trigger_variation_id: variation_id })
    .then(payload => {
      setIsLoading(false);
      dispatch({ type: FETCH_COMPLETE, payload: { attributes: params.attributes, ...payload } });
    })
    .catch(e => {
      setIsLoading(false);
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const execFetchUpdatedComponents = (dispatch, args, setIsLoading) => {
  const { id, params, updateVariationsBox, attrId, app, vip_filters } = args;
  setIsLoading(true);
  APIService.getItemUpdateVariation(id, { ...params, id, app, vip_filters })
    .then(payload => {
      updateVariationsBox(id, attrId, payload);
      setIsLoading(false);
      dispatch({ type: FETCH_COMPLETE, payload: { attributes: params.attributes, ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      setIsLoading(false);
    });
};

export { execFetchComponents, execFetchUpdatedComponents };
