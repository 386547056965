import {
  trackEvent,
  trackEventWithCustomField,
  trackEventWithCustomFields,
  trackPage,
} from '@vpp-frontend-components/common';
import { tracking } from '../../../pages/questions-ai/events/tracking';
import { arrayToObjectById } from '../../../reducers/helpers/arrayToObjectById';
import APIService from '../../../services/api';
import { QUESTIONS_AI_DATADOG_KEY_CASES } from '../../../services/frontend-statsd/config/allowed-keys';
import {
  QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES,
  QUESTIONS_AI_REASON_TAG_VALUES,
} from '../../../services/frontend-statsd/config/allowed-tags';
import { feStatsdHelper } from '../../../utils/frontendStatsHelper';
import {
  CREATE_QUESTION_ERROR,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_START,
  FETCH_ERROR,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  FETCH_QUESTION_AI_START,
  INITIAL_QUESION_AI,
  SHOW_SNACKBAR,
} from '../../vip/types';
import { getOnCreateQuestionFromAi } from '../../getters/vip/questions-ai';

const execOnCreateQuestionFromAi = (dispatch, args) => {
  const { app, itemId, text, track, snackbar_message, suggestionTrack, statsdConfig } = args;

  if (suggestionTrack) {
    const {
      melidata_event: {
        event_data: { origin },
      },
    } = suggestionTrack;

    switch (origin) {
      case 'feedback':
        trackEvent(suggestionTrack);
        break;

      default:
        trackPage(suggestionTrack);
        break;
    }
  }

  dispatch({ type: CREATE_QUESTION_START });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload) {
        if (payload.stock_track) {
          trackEvent(payload.stock_track);
        }

        if (!payload.stock_modal) {
          const extraFields = payload.extra_post_event_data || {};
          extraFields.failed = !payload.success;
          trackEventWithCustomFields(track, extraFields);
        }

        if (payload.redirect) {
          window.location = payload.redirect.permalink;
        } else {
          feStatsdHelper({
            key: QUESTIONS_AI_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_SUCCESS,
            },
          });
          dispatch({ type: CREATE_QUESTION_FROM_AI_COMPLETE, payload });
          dispatch({ type: INITIAL_QUESION_AI });
          dispatch({
            type: SHOW_SNACKBAR,
            params: { message: snackbar_message, type: 'success', delay: 3000, called_from: 'questions_ai' },
          });
        }
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const execPostQuestionFromAi = (dispatch, args) => {
  const { app, productId, itemId, text, track, attributes, quantity, source, statsdConfig, getState } = args;
  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.SEND_QUESTION,
    },
  });

  dispatch({ type: FETCH_QUESTION_AI_START });

  if (track) {
    tracking({ track });
  }

  APIService.postQuestion(itemId, { productId, text, app, attributes, quantity })
    .then(payload => {
      if (payload) {
        if (payload.track) {
          tracking({ track: payload.track });
        }

        feStatsdHelper({
          key: QUESTIONS_AI_DATADOG_KEY_CASES.SUGGESTION_RESPONSE,
          statsdConfig,
          additionalTags: {
            classification: payload.track?.melidata_event?.event_data?.classification,
          },
        });

        if (payload.make_action) {
          execOnCreateQuestionFromAi(
            dispatch,
            getOnCreateQuestionFromAi(
              payload.make_action.config.user_question,
              payload.make_action.snackbar_message,
              track,
              source,
              getState,
            ),
          );
        } else {
          dispatch({ type: FETCH_QUESTION_AI_COMPLETE, payload: arrayToObjectById(payload.components) });
        }
      }
    })
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.SUGGESTION_RESPONSE_NOT_LOADED,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_QUESTION_AI_ERROR, error: e });
    });
};

const execIsUseful = (dispatch, args) => {
  const { app, itemId, useful, id, response, track, statsdConfig } = args;
  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      like: Boolean(useful),
    },
  });

  if (track) {
    tracking({ track });
  }

  APIService.likeDislikeQuestionsAi(itemId, { useful, id, response, app })
    .then(() => {})
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.FEEDBACK_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

export { execPostQuestionFromAi, execOnCreateQuestionFromAi, execIsUseful };
