import HeaderFallback from './fallback-header';
import PriceFallback from './fallback-price';
// import GalleryFallback from './fallback-gallery';

const fallbackComponentsMap = {
  header: props => <HeaderFallback {...props} />,
  price: props => <PriceFallback {...props} />,
  // gallery: props => <GalleryFallback {...props} />,
  // [`fixed.gallery`]: props => <GalleryFallback {...props} />,
};

export default fallbackComponentsMap;
