import React, { useContext, useEffect, useState, startTransition, useRef } from 'react';
import { bool, string, shape, func } from 'prop-types';
import classNames from 'classnames';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import BottomSheet from '@andes/bottom-sheet';
import { Modal } from '@andes/modal';
import { VisuallyHidden } from '@andes/common';
import StaticPropsContext from '../context/static-props';
import IconFactory from '../icons/factory-builder';
import { trackEvent } from '../../lib/tracking';
import { constants } from '../../utils/constants';
import {
  ADD_MODAL_HEADER_STYLE_METHOD,
  CALLBACK_METHOD,
  CHANGE_TITLE_METHOD,
  CLOSE_METHOD,
  DESKTOP,
  REMOVE_MODAL_HEADER_STYLE_METHOD,
  RESIZE_HEIGHT_METHOD,
  SHOW_SNACKBAR_METHOD,
  SNACKBAR_LIST_LIMIT_TYPE,
  BOOKMARK_MODAL_IFRAME_CLOSE,
  SHOW_GIFT_REGISTRY_EXPLANATION,
  WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP,
} from './constants';

const namespace = 'ui-pdp-bookmark';

const THEME_MODE = { CIRCLE: 'circle' };

const Bookmark = ({
  runCatchErrorBoundary,
  add,
  className,
  csrfToken,
  fetchBookmark,
  isBookmarked,
  itemId,
  onClick,
  productId,
  remove,
  themeMode,
  wishlist,
  closeWishlistModalBS,
  showSnackbar,
  updateGiftRegistry,
  toggleGiftRegistryCheckbox,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { DEVICE_TYPE } = constants;
    const [isBookmark, setIsBookmark] = useState(isBookmarked);
    const ref = useRef();

    const { deviceType } = useContext(StaticPropsContext);
    const [loading, setLoading] = useState(true);
    const [titleModal, setTitleModal] = useState(wishlist?.title);
    const [currentStep, setCurrentStep] = useState(null);

    useEffect(() => {
      setIsBookmark(isBookmarked);
    }, [isBookmarked]);

    /* istanbul ignore next */
    const onClose = isSuccess => {
      closeWishlistModalBS(isSuccess);
      setLoading(true);
      setTitleModal(wishlist?.title);
    };

    /* istanbul ignore next */
    const onCloseWithoutBookmarkSelection = () => {
      if (currentStep === SHOW_GIFT_REGISTRY_EXPLANATION) {
        const url = new URL(wishlist?.target);
        ref.current.contentWindow.postMessage({ type: BOOKMARK_MODAL_IFRAME_CLOSE }, url.origin);
        return;
      }

      closeWishlistModalBS();
      setLoading(true);
      setTitleModal(wishlist?.title);
    };

    const watchCurrentStep = e => {
      const { method, args } = e?.data || {};
      if (method === WISHLIST_LIST_SELECTOR_SET_CURRENT_STEP) {
        setCurrentStep(args?.currentStep);
      }
    };

    const onInternalClick = e => {
      setIsBookmark(!isBookmark);
      startTransition(() => {
        e.preventDefault();

        if (isBookmark) {
          trackEvent(remove.track);
        } else {
          trackEvent(add.track);
        }
        onClick(e);
      });
    };

    const changeModalHeaderStyle = e => {
      if (deviceType !== 'desktop') {
        return;
      }

      const modalHeader = document.querySelector('.andes-modal__header');
      /* istanbul ignore next */
      if (e.data.method === ADD_MODAL_HEADER_STYLE_METHOD && modalHeader) {
        modalHeader.setAttribute('class', 'andes-modal__header andes-modal__header--shadow');
      }

      /* istanbul ignore next */
      if (e.data.method === REMOVE_MODAL_HEADER_STYLE_METHOD && modalHeader) {
        modalHeader.setAttribute('class', 'andes-modal__header');
      }
    };

    const handleShowSnackbarListLimit = e => {
      if (e?.data?.method === SHOW_SNACKBAR_METHOD) {
        const { message = 'Default message', delay = { duration: 3000 } } = e?.data?.args || {};

        showSnackbar({
          message,
          type: SNACKBAR_LIST_LIMIT_TYPE,
          delay: delay?.duration || 3000,
        });
      }
    };

    /* istanbul ignore next */
    const handleMessage = e => {
      watchCurrentStep(e);
      changeModalHeaderStyle(e);
      handleShowSnackbarListLimit(e);
      if (e?.data?.method === CHANGE_TITLE_METHOD) {
        setTitleModal(e.data.args.createListTitle);
      }

      const isDesktop = deviceType === DESKTOP;
      if (e.data.type === 'bookmark:refresh' && e.data.id === itemId) {
        fetchBookmark();
      }

      if (e.data.method === CLOSE_METHOD) {
        onClose();
      }

      if (e.data.method === RESIZE_HEIGHT_METHOD) {
        const padding = isDesktop ? 34 : 44;
        const maxHeightPercentBS = 0.66;
        const heightChange =
          e.data.args.height + padding < window.innerHeight * maxHeightPercentBS
            ? e.data.args.height
            : window.innerHeight * maxHeightPercentBS - padding;

        const selector = isDesktop ? '.andes-modal__content' : '.andes-bottom-sheet';
        document.querySelector(selector).style.height = `${heightChange + padding}px`;
      }

      if (e.data.method === CALLBACK_METHOD) {
        const { component, checked, should_update } = e.data.args;
        const COMPONENT_TYPE = 'snackbar';
        const MESSAGE_TYPE_SUCCESS = 'success';
        const CALLED_FROM = 'wishlist_save_button';
        const TAB = 'tab';
        const LIST = 'list';
        if (component?.type === COMPONENT_TYPE) {
          const isChecked = checked || false;
          // comes from create core list
          if (should_update === undefined && !isBookmarked) {
            fetchBookmark();
          }
          // comes from create GR list
          if (should_update === true) {
            if (component?.message_type !== 'error') {
              updateGiftRegistry(isChecked);
            }
            // comes from add to list
          } else if (should_update === false) {
            if (!isBookmarked) {
              fetchBookmark();
            }
            toggleGiftRegistryCheckbox(isChecked);
          }

          const isSuccess = component?.message_type === MESSAGE_TYPE_SUCCESS;
          const hubUrl = new URL(wishlist?.target);
          const goToTab = component?.action?.target.includes(`${TAB}=${LIST}`);
          const newOrigin = window.location.origin.toString();
          const newHubUrl = new URL(hubUrl.origin, newOrigin);

          newHubUrl.search = '';
          if (goToTab) {
            const pathname = component?.action?.target.split('?')[0];
            newHubUrl.pathname = decodeURIComponent(`${pathname}`);
            newHubUrl.searchParams.set(TAB, LIST);
          } else {
            newHubUrl.pathname = decodeURIComponent(`${component?.action?.target}`.toString());
          }

          showSnackbar({
            message: component?.message,
            type: component?.message_type,
            className: 'snackbar--add-bookmark',
            delay: component?.delay?.duration || 6000,
            called_from: CALLED_FROM,
            action: {
              text: component.action?.label?.text,
              onClick: () => {
                window.location.assign(newHubUrl);
              },
            },
          });
          onClose(isSuccess);
        }
      }
    };

    useEffect(() => {
      if (window) {
        window.addEventListener('message', handleMessage);
      }

      return () => {
        window.removeEventListener('message', handleMessage);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isCircleMode = themeMode === THEME_MODE.CIRCLE;

    const classes = classNames(namespace, `${namespace}__link-bookmark`, className, {
      [`${namespace}__circle-bookmark`]: isCircleMode,
    });
    const iconFilledClassNames = classNames(`${namespace}__icon-bookmark-fill`, {
      [`${namespace}__icon-bookmark-fill--active`]: isBookmark,
    });
    const addLabelBookmarked = add?.label?.text;
    const deleteLabelBookmarked = remove?.label?.text;
    const textBookmark = isBookmark ? deleteLabelBookmarked : addLabelBookmarked;
    const href = `/p/${productId || itemId}/bookmark/${isBookmark ? 'remove' : 'add'}/${itemId}`;

    const renderSmallLabel = () => {
      const showVisuallyHidden =
        isCircleMode || deviceType === DEVICE_TYPE.DESKTOP || deviceType === DEVICE_TYPE.TABLET;

      return showVisuallyHidden ? <VisuallyHidden>{textBookmark}</VisuallyHidden> : textBookmark;
    };

    return (
      <>
        <form className={classes} method="post" action={href}>
          <input type="hidden" name="_csrf" value={csrfToken} />
          <button
            data-testid="bookmark-anchor"
            className={`${namespace}__link-bookmark`}
            onClick={onInternalClick}
            role="switch"
            type="submit"
            aria-checked={isBookmark}
          >
            {IconFactory({ id: 'bookmark' }, `${namespace}__icon-bookmark`)}
            {IconFactory({ id: 'bookmark' }, iconFilledClassNames)}
            <small data-testid="bookmark-label-hidden" className={classNames(`${namespace}__label`)}>
              {renderSmallLabel()}
            </small>
          </button>
        </form>

        {!!wishlist?.target && deviceType === DESKTOP && (
          <>
            <Modal
              srLabel={wishlist?.label}
              open={wishlist?.show}
              handleClose={onCloseWithoutBookmarkSelection}
              type="large"
              closeButtonSrLabel={wishlist?.close_modal_label}
              title={!loading ? titleModal : ''}
              className={`${namespace}__modal-wishlist`}
            >
              <div style={{ width: 420, height: !loading ? '100%' : 258 }}>
                {loading && (
                  <ProgressIndicatorCircular modifier="fullscreen" size="xlarge" label={wishlist?.loading_text} />
                )}
                <iframe
                  ref={ref}
                  id="wishlist-iframe"
                  title="wishlist-iframe"
                  onLoad={() => setLoading(false)}
                  src={wishlist?.target}
                  width="100%"
                  height="100%"
                  style={{ display: loading ? 'none' : 'block', border: 'none' }}
                />
              </div>
            </Modal>
          </>
        )}

        {!!wishlist?.target && deviceType !== DESKTOP && (
          <BottomSheet
            aria-label={wishlist?.label}
            open={wishlist?.show}
            onClose={onCloseWithoutBookmarkSelection}
            initialHeight="auto"
            closable={wishlist?.action?.close_button_position || 'inner'}
          >
            {loading && <ProgressIndicatorCircular modifier="fullscreen" size="medium" />}
            <iframe
              id="wishlist-iframe"
              title="wishlist-iframe"
              onLoad={() => setLoading(false)}
              src={wishlist?.target}
              width="100%"
              height="100%"
              style={{ display: loading ? 'none' : 'block', border: 'none' }}
            />
          </BottomSheet>
        )}
      </>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Bookmark.propTypes = {
  add: shape({
    label: shape({
      text: string.isRequired,
    }),
    icon: shape({
      id: string.isRequired,
    }),
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }).isRequired,
  }).isRequired,
  csrfToken: string,
  className: string,
  remove: shape({
    label: shape({
      text: string.isRequired,
    }),
    icon: shape({
      id: string.isRequired,
    }),
    track: shape({ melidata_event: shape({}), analytics_event: shape({}) }).isRequired,
  }).isRequired,
  fetchBookmark: func,
  isBookmarked: bool,
  itemId: string,
  onClick: func.isRequired,
  productId: string,
  themeMode: string,
  closeWishlistModalBS: func,
  wishlist: shape({
    label: string,
    show: bool,
    target: string,
    title: string,
    showOnlyForWishlistVariant: bool,
    action: shape({
      close_button_position: string.isRequired,
    }),
  }),
  showSnackbar: func,
  updateGiftRegistry: func,
  toggleGiftRegistryCheckbox: func,
  runCatchErrorBoundary: func,
};

Bookmark.defaultProps = {
  className: '',
  isBookmarked: false,
  title: '',
  isFetching: false,
  runCatchErrorBoundary: () => {},
};

export default React.memo(Bookmark);
