import React from 'react';
import { node, shape, string } from 'prop-types';
import { feStatsdHelper } from '../utils/frontendStatsHelper';
import trackNewrelic from '../services/client/new-relic.client';

import {
  VPP_BOUNDARY_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
} from '../services/frontend-statsd/config/allowed-keys';

// Datadog config - ERROR BOUNDARY
const statsdConfig = {
  keyPrefix: VPP_BOUNDARY_DATADOG_KEY_PREFIX,
};

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { metricDataConfig } = this.props;
    const { app, deviceType, componentId, isCritical, ownership } = metricDataConfig;
    const metricData = {
      referer_app: app,
      device_type: deviceType,
      catch_context: 'client_side',
      component_id: componentId,
      is_critical: isCritical,
      ownership,
    };

    feStatsdHelper({
      key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_COMPONENT,
      statsdConfig,
      additionalTags: metricData,
      shouldLogMessage: true,
      additionalInfo: { ...metricData, error: error.stack },
      messageTitle: 'Vpp-ErrorBoundary - ComponentBoundary',
    });

    trackNewrelic({ error, info });
  }

  render() {
    const { hasError } = this.state;
    const { children, metricDataConfig } = this.props;
    if (hasError) {
      window.ERROR_COMPONENT_NAME = metricDataConfig?.componentId;
      return null;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
  metricDataConfig: shape({
    componentId: string,
  }),
};

export default ErrorBoundary;
