import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { loadable } from 'nordic/lazy';
import classNames from 'classnames';
import StaticPropsContext from '../../context/static-props';
import ActionsForm from './actions-form';
import { mapOffsetsToCamelCase } from './utils/mapOffsetsToCamelCase';
import { generateKeyToComponent } from '../../../utils/validators';

const Action = loadable(() => import('./action'));

const { useContext } = React;

const namespace = 'ui-pdp-actions';

const ACTION_TYPE_PRIMARY = 'primary';
const ACTION_TYPE_SECONDARY = 'secondary';
const ACTION_TYPE_TERTIARY = 'tertiary';
const ACTION_HIERARCHY_QUIET = 'quiet';
const ACTION_HIERARCHY_LOUD = 'loud';
const ACTION_HIERARCHY_TRANSPARENT = 'transparent';

const getActionHierarchy = type => {
  switch (type) {
    case ACTION_TYPE_PRIMARY:
      return ACTION_HIERARCHY_LOUD;
    case ACTION_TYPE_SECONDARY:
      return ACTION_HIERARCHY_QUIET;
    case ACTION_TYPE_TERTIARY:
      return ACTION_HIERARCHY_TRANSPARENT;
    default:
      return ACTION_HIERARCHY_LOUD;
  }
};

const Actions = ({
  available_quantity: availableQuantity,
  actions,
  className,
  form,
  size,
  onSubmit,
  showVariationsError,
  showAddToCartModal,
  showQuestionsAiModal,
  triggerBuyNowOnePayForAll,
  triggerSequencer,
  saveFrontendStatsd,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { isWebview, deviceType } = useContext(StaticPropsContext);

    return (
      <div className={classNames(className, namespace)}>
        <div
          className={classNames(`${namespace}__container`, {
            [`${namespace}__container--one-element`]: actions.length === 1,
          })}
        >
          {actions.map(action => (
            <Action
              key={generateKeyToComponent(action)}
              itemId={form && form.item_id}
              showAddToCartModal={showAddToCartModal}
              showQuestionsAiModal={showQuestionsAiModal}
              triggerBuyNowOnePayForAll={triggerBuyNowOnePayForAll}
              triggerSequencer={triggerSequencer}
              saveFrontendStatsd={saveFrontendStatsd}
              showVariationsError={showVariationsError}
              actionType={action.action_type}
              fallbackUrl={action.fallback}
              size={size}
              {...action}
              onSubmit={onSubmit}
              disabled={action.disabled}
              tooltip={
                action.disabled && action.disabled_text
                  ? {
                      content: action.disabled_text,
                      mouseLeaveDelay: -0.8,
                      ...mapOffsetsToCamelCase(action.tooltip || {}),
                    }
                  : null
              }
              deviceType={deviceType}
              hierarchy={getActionHierarchy(action.type)}
            />
          ))}
          {form ? <ActionsForm form={form} isWebview={isWebview} availableQuantity={availableQuantity} /> : null}
        </div>
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Actions.propTypes = {
  actions: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      type: string.isRequired,
      target: string,
      track: shape({}),
    }),
  ).isRequired,
  available_quantity: shape({}),
  className: string,
  form: shape(),
  size: string,
  onSubmit: func,
  showVariationsError: func,
  triggerBuyNowOnePayForAll: func,
  triggerSequencer: func,
  saveFrontendStatsd: func,
  showAddToCartModal: func,
  showQuestionsAiModal: func,
  runCatchErrorBoundary: func,
};

Actions.defaultProps = {
  className: '',
  form: null,
  available_quantity: null,
  size: null,
  onSubmit: null,
  showVariationsError: null,
  triggerBuyNowOnePayForAll: null,
  saveFrontendStatsd: null,
  triggerSequencer: null,
  showAddToCartModal: null,
  showQuestionsAiModal: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(Actions);
