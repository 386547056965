const getFetchComponents = (id, params, getState) => {
  const { app, vip_filters, variation_id } = getState();
  return { id, params, app, vip_filters, variation_id };
};

const getFetchUpdatedComponents = (id, params, updateVariationsBox, attrId, getState) => {
  const { app, vip_filters } = getState();
  return { id, params, updateVariationsBox, attrId, app, vip_filters };
};

export { getFetchComponents, getFetchUpdatedComponents };
