import { runSchedulerTask } from '@vpp-frontend-components/common';
import APIService from '../../../services/api';
import HistoryService from '../../../services/history';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_ON_SET_HI_QUANTITY_COMPLETE,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_START,
  HIDE_HI_QUANTITY_ERROR,
} from '../../vip/types';
import getSelectedAttributes from '../../utils/get-selected-attributes';

const execCheckOnBulkSaleSetQuantity = async (dispatch, args) => {
  const {
    variation_id,
    item_id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    components,
    hasDeferredErrors,
    bulk_sale_quantity,
    sale_unit_quantity,
  } = args;

  dispatch({
    type: FETCH_START,
    quantity: sale_unit_quantity,
  });

  try {
    const result = await APIService.checkBulkSaleQuantity({
      item_id,
      variation_id,
      bulk_sale_quantity,
      sale_unit_quantity,
    });

    if (result?.stock_error_message?.error_label?.text) {
      dispatch({ type: FETCH_END });
      return result;
    }

    const attributes = getSelectedAttributes(components);

    runSchedulerTask(() => {
      HistoryService.pushParam('quantity', sale_unit_quantity, true);
    }, 'background');

    const payload = await APIService.getItem({
      id: item_id,
      platform,
      app,
      pdp_filters,
      vip_filters,
      quantity: sale_unit_quantity,
      shipping_option_id: selectedShippingOptionId,
      attributes,
      has_deferred_errors: hasDeferredErrors,
    });

    dispatch({ type: HIDE_HI_QUANTITY_ERROR });
    dispatch({ type: FETCH_ON_SET_HI_QUANTITY_COMPLETE, payload, quantity: sale_unit_quantity });

    return result;
  } catch (error) {
    dispatch({ type: FETCH_ERROR, error });

    return null;
  } finally {
    dispatch({ type: FETCH_END });
  }
};

const execFetchItemOnSetQuantity = (dispatch, args) => {
  const {
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    selectedShippingOptionId,
    attributes,
    hasDeferredErrors,
    quantity,
  } = args;

  dispatch({
    type: FETCH_ON_SET_QUANTITY_START,
    quantity,
  });

  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: selectedShippingOptionId,
    attributes,
    has_deferred_errors: hasDeferredErrors,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SET_QUANTITY_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

export { execCheckOnBulkSaleSetQuantity, execFetchItemOnSetQuantity };
