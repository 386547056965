import { trackEvent } from '../../../lib/tracking';
import APIService from '../../../services/api';
import { VPP_SEQUENCER_DATADOG_KEY_CASES } from '../../../services/frontend-statsd/config/allowed-keys';
import { REASON_TAG_VALUES, UNKNOWN_VALUE } from '../../../services/frontend-statsd/config/allowed-tags';
import { feStatsdHelper } from '../../../utils/frontendStatsHelper';
import { getDefaultErrorMessage } from '../../utils/getDefaultErrorMessage';
import {
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
  getLoadingTimeInterval,
  newPayloadFetchSequencer,
  redirectOrShowSnackbar,
  RENDER_MODES,
  updateOnePayForAllTrack,
} from '../../utils/onePayForAllUtils';
import { FETCH_SEQUENCER, ON_DEMAND_IFRAME, PRELOAD_IFRAME, SHOW_SNACKBAR } from '../../vip/types';

const execTriggerBuyNowOnePayForAll = (dispatch, args) => {
  const { actionKey, siteId, form, actions, quantity } = args;

  dispatch({
    type: FETCH_SEQUENCER,
    payload: newPayloadFetchSequencer(actions, true, actionKey),
  });

  APIService.buyNowOnePayForAll({ ...form, quantity })
    .then(response => {
      window.location.href = response.target;
    })
    .catch(e => {
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e.response.data.displayMessage ?? getDefaultErrorMessage(siteId),
          type: 'error',
          delay: 3000,
          called_from: 'vip',
        },
      });
    });
};

/* eslint-disable complexity */
const execTriggerSequencer = (dispatch, args) => {
  const {
    action,
    actions,
    actionKey,
    target,
    fallback,
    display_instantly,
    track,
    siteId,
    statsdConfig,
    preload,
  } = args;

  let fallbackUrl = fallback;

  if (!display_instantly) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, true, actionKey),
    });
  }

  // DATADOG - action click
  feStatsdHelper({
    key: VPP_SEQUENCER_DATADOG_KEY_CASES.ACTION_CLICK,
    additionalTags: {
      main_action_target: Boolean(target),
      main_action_fallback: Boolean(fallback),
      main_action_empty: !target && !fallback,
    },
    additionalInfo: { action },
    shouldLogMessage: !target || !fallback,
    statsdConfig,
  });

  if (!target) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, false, actionKey),
    });
    redirectOrShowSnackbar({
      dispatch,
      siteId,
      fallbackToRedirect: null,
      reason: REASON_TAG_VALUES.NO_MAIN_ACTION_TARGET,
      statsdConfig,
      trackData: track,
    });
    return;
  }

  if (display_instantly) {
    if (preload?.isIframeLoaded) {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: true,
        },
        statsdConfig,
      });
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: true,
          fallback: fallbackUrl,
          statsdConfig,
        },
      });
    } else {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: false,
        },
        statsdConfig,
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, true, actionKey),
      });
    }
  }

  const params = Object.fromEntries(new URLSearchParams(target.split('?')[1]));

  params.is_preload_shown = Boolean(preload?.isIframeLoaded);

  APIService.getSequencerOnePayForAll(params)
    .then(response => {
      const knownCases = [
        ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD,
        ACTIONS_SEQUENCER.BOTTOM_SHEET,
        ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD,
        ACTIONS_SEQUENCER.LANDING,
        ACTIONS_SEQUENCER.SNACKBAR,
        ACTIONS_SEQUENCER.NAVIGATE,
      ];
      const actionType = response?.actions?.action_type ?? '';
      const targetUrl = response?.actions?.target;
      const responseFallback = response?.actions?.fallback;
      if (responseFallback) {
        fallbackUrl = responseFallback;
      }
      const fallbackConfig = {
        fallbackUrl,
        fallbackMessage: response?.actions?.fallback_message,
        timeoutInitialLoad: response?.actions?.timeout,
        timeoutWebviewMessage: response?.actions?.timeout_webview_message,
      };

      const isDismissible = response?.actions?.bottom_sheet?.is_dismissible;
      const snackbarMessage = response?.actions?.snackbar?.message;
      const snackbarType = response?.actions?.snackbar?.type;
      const sequencerTrack = response?.actions?.track;
      const infoUpdate = response?.actions?.info_update || false;

      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      if (sequencerTrack) {
        trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.RESPONSE));
      }

      // DATADOG - get response
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.GET_RESPONSE,
        additionalTags: {
          response_target: Boolean(targetUrl),
          response_fallback: Boolean(responseFallback),
          response_empty: !targetUrl && !responseFallback,
          response_case: actionType,
        },
        additionalInfo: { response, params },
        shouldLogMessage: !targetUrl || !responseFallback || !knownCases.includes(actionType),
        statsdConfig,
      });

      switch (actionType) {
        // NOTA: bottom_sheet_with_preload o landing_with_preload "hacen lo mismo"
        // El modo de visualización es responsabilidad / tarea de la webview
        // Pues nosotros mostramos un iframe del 100% y la misma webview decide que dibujar dentro
        case ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD:
        case ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD:
          if (!preload?.isIframeLoaded) {
            const loadingTime = getLoadingTimeInterval({
              nowTime: Date.now(),
              loadingStartTime: preload?.loadingStartTime,
            });
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_WEBVIEW_PRELOADED,
              tags: { loading_time: loadingTime || UNKNOWN_VALUE },
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: true,
              fallback: fallbackUrl,
              infoUpdate,
              statsdConfig,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.BOTTOM_SHEET:
        case ACTIONS_SEQUENCER.LANDING:
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET
                  ? REASON_TAG_VALUES.NO_BOTTOMSHEET_TARGET
                  : REASON_TAG_VALUES.NO_LANDING_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.LOADING));
          }

          dispatch({
            type: ON_DEMAND_IFRAME,
            params: {
              show: true,
              src: targetUrl,
              isRedirectFlow: false,
              renderMode:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET ? RENDER_MODES.CONTAINER : RENDER_MODES.FULLSCREEN,
              fallbackConfig,
              statsdConfig,
              isDismissible,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.SNACKBAR:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
              snackbar_type: snackbarType || 'error',
            },
          });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snackbarMessage ?? getDefaultErrorMessage(siteId),
              type: snackbarType ? snackbarType.toLowerCase() : 'error',
              delay: 3000,
              called_from: 'vip',
            },
          });
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.SNACKBAR));
          }
          break;
        case ACTIONS_SEQUENCER.NAVIGATE:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_NAVIGATE_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.NAVIGATE));
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          window.location.href = targetUrl;
          break;
        default:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          redirectOrShowSnackbar({
            dispatch,
            siteId,
            fallbackToRedirect: null,
            reason: REASON_TAG_VALUES.UNKNOWN_CASE,
            message: fallbackConfig.fallbackMessage,
            statsdConfig,
            trackData: sequencerTrack,
          });
          break;
      }
    })
    .catch(error => {
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: false,
        },
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });
      redirectOrShowSnackbar({
        dispatch,
        siteId,
        fallbackToRedirect: fallbackUrl,
        reason: REASON_TAG_VALUES.CATCH_SEQUENCER,
        additionalInfo: { error, params },
        shouldLogMessage: true,
        statsdConfig,
        trackData: track,
      });
    });
};
/* eslint-enable complexity */

export { execTriggerBuyNowOnePayForAll, execTriggerSequencer };
