import React from 'react';
import { bool, string, func } from 'prop-types';
import { loadable } from 'nordic/lazy';
import { RecommendationsList, RecommendationsListPads } from '@recommendations-fe/list';
import RecommendationsIconFactory from './icon-factory';

const RecommendationsCardHorizontal = loadable(() => import('./recomendations-card'), {
  resolveComponent: components => components.RecommendationsCardHorizontal,
});

RecommendationsCardHorizontal.preload();

const Recommendations = ({
  isPolycard,
  thousandSeparator,
  decimalSeparator,
  runCatchErrorBoundary = () => {},
  ...props
}) => {
  try {
    return (
      <RecommendationsList
        ListComponent={RecommendationsListPads}
        listType="pads"
        Card={RecommendationsCardHorizontal}
        IconFactory={RecommendationsIconFactory}
        contextProps={{
          type: 'list',
          thousandSeparator,
          decimalSeparator,
        }}
        cardProps={{
          polyClassName: 'recos-polycard poly-card--mobile',
        }}
        isPolycard={isPolycard}
        {...props}
      />
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  runCatchErrorBoundary: func,
};

export default Recommendations;
