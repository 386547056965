// DO NOT COMMIT - THIS FILE WAS CHANGED FOR ESBUILD, BEFORE COMMITTING RUN 'npm run esbuild-discard-changes' TO RESTORE THE FILE TO THE ORIGINAL VERSION

import React, { useMemo, useState, useContext, startTransition } from 'react';
import { LazyHydrate, loadable } from 'nordic/lazy';
import classnames from 'classnames';
import { Script } from 'nordic/script';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { constants } from '../../utils/constants';
import { DEFAULT_LIGHTBOX_SETTINGS } from './constants';
import { getShapes } from '../dots-navigation';
import Bookmark from '../bookmark';
import Image from '../image';
import Share from '../share/share.mobile';
import useGalleryHandlers from './hooks/use-gallery-handlers';
import GalleryCarousel from './components/mobile/gallery.carousel';
import { PROVIDER } from '../figure/constants';
import { useClips } from './hooks/use-clips';
import getLightboxPictures from './events/get-lightbox-pictures';
import GalleryPropsContext from './context/gallery.contex';

const namespace = 'ui-pdp-gallery';
const { DEVICE_TYPE } = constants;
const ImageLoader = () => <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />;
const Lightbox = loadable(() => import('../lightbox'));
const VirtualTourModal = loadable(() => import('../virtual-tour-modal'));
const ClipsWorkerComponent = loadable(() => import('./components/mobile/clips-worker-component-mobile'), {
  resolveComponent: components => components.ClipsWorkerComponent,
});

/* Pictures should have msrc to avoid photoswipe blink zoom */
const GalleryMobile = props => {
  const {
    accessibility_text,
    bookmark,
    clipsConfig,
    galleryType,
    highlightedMultimedia,
    id,
    app,
    logo,
    figures,
    onClickBookmark,
    openWishlistModalBS,
    updateGiftRegistry,
    closeWishlistModalBS,
    toggleGiftRegistryCheckbox,
    fetchBookmark,
    share,
    showSnackbar,
    track,
    minHeight,
    maxHeight,
    overlayAlpha,
  } = props;
  const deviceType = DEVICE_TYPE.MOBILE;
  const showImageLoader = figures?.length === 1;
  const { clipsRef, clipHandlerAsync } = useClips();

  const filterImagesAndClips = ({ provider }) => [PROVIDER.CLIP, PROVIDER.IMAGE].includes(provider);
  const handleLightboxOnTouch = (...params) => clipsRef?.current && clipsRef?.current?.handleTouch(...params);

  const { position, setCurrentIndex, setIsFullscreen } = useContext(GalleryPropsContext) || {};

  const [activeModalTab, setActiveModalTab] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);
  const lightboxPictures = getLightboxPictures({ figures, filterImagesAndClips });
  const galleryClassname = classnames(namespace, { [`${namespace}-with-logo`]: logo });
  const shapes = useMemo(() => getShapes(figures), [figures]);

  const clipsWorkerComponentProps = {
    clipsConfig,
    showSnackbar,
    namespace,
    deviceType,
    figures,
    ref: clipsRef,
  };
  const setHeaderUnclickeable = unclickeable => {
    const header = document.querySelector('.nav-header');
    if (header) {
      if (unclickeable) {
        header.style.pointerEvents = 'none';
      } else {
        header.style.pointerEvents = 'auto';
      }
    }
  };

  const handleBeforeOpen = () => {
    setIsFullscreen(true);
  };

  const handleLightboxOnClose = () => {
    setModalVisibility(false);

    startTransition(() => {
      setTimeout(() => setHeaderUnclickeable(false), 200);

      setIsFullscreen(false);
    });
  };

  const { handleTapFigure } = useGalleryHandlers({
    clipsRef,
    clipsConfig,
    track,
    setModalVisibility,
    setHeaderUnclickeable,
    setActiveModalTab,
    clipHandlerAsync,
  });

  return (
    <div className={galleryClassname}>
      {showImageLoader && <ImageLoader />}
      {logo && (
        <div className={`${namespace}__logo-container`}>
          <Image
            src={logo.url.src}
            alt={logo.alt}
            lazyload={false}
            width={64}
            height={64}
            className={`${namespace}__logo`}
          />
        </div>
      )}
      <GalleryCarousel
        id={id}
        figures={figures}
        clipsConfig={clipsConfig}
        galleryType={galleryType}
        minHeight={minHeight}
        maxHeight={maxHeight}
        counter={position?.current}
        setCounter={setCurrentIndex}
        shapes={shapes}
        accessibility_text={accessibility_text}
        onTapFigure={handleTapFigure}
        overlayAlpha={overlayAlpha}
        app={app}
      />
      {clipsConfig?.hasClips && <ClipsWorkerComponent {...clipsWorkerComponentProps} />}
      {highlightedMultimedia && !!activeModalTab && (
        <LazyHydrate whenIdle>
          <VirtualTourModal
            visible={modalVisibility}
            activeTab={activeModalTab}
            highlightedMultimedia={highlightedMultimedia}
            figures={figures?.filter(filterImagesAndClips)}
            figureIndex={position?.current}
            onChange={newTab => setActiveModalTab(newTab)}
            setIndex={setCurrentIndex}
            onClose={handleLightboxOnClose}
            deviceType={DEVICE_TYPE.MOBILE}
            hasClips={clipsConfig?.hasClips}
            onBeforeOpen={handleBeforeOpen}
            onTouch={handleLightboxOnTouch}
          />
        </LazyHydrate>
      )}
      {!highlightedMultimedia && modalVisibility && (
        <Lightbox
          pictures={lightboxPictures}
          deviceType={DEVICE_TYPE.MOBILE}
          hasClips={clipsConfig?.hasClips}
          onBeforeOpen={handleBeforeOpen}
          onClose={handleLightboxOnClose}
          onTouch={handleLightboxOnTouch}
          settings={DEFAULT_LIGHTBOX_SETTINGS.mobile}
          shapes={shapes}
        />
      )}
      {bookmark && (
        <Bookmark
          {...bookmark}
          onClick={onClickBookmark}
          openWishlistModalBS={openWishlistModalBS}
          closeWishlistModalBS={closeWishlistModalBS}
          updateGiftRegistry={updateGiftRegistry}
          showSnackbar={showSnackbar}
          toggleGiftRegistryCheckbox={toggleGiftRegistryCheckbox}
          fetchBookmark={fetchBookmark}
        />
      )}
      {share && <Share modifier="gallery" iconVariation="gallery" showLabel={false} {...share} />}
    </div>
  );
};
GalleryMobile.propTypes = {
  track: shape({}),
  id: string.isRequired,
  galleryType: string,
  accessibility_text: string,
  figures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  share: shape({
    shareActions: arrayOf(
      shape({
        target: string,
        label: shape({
          text: string,
        }),
        icon: shape({
          id: string,
        }),
      }),
    ),
    permalink: string,
    title: shape({
      text: string,
      color: string,
    }),
    action: shape({
      label: shape({
        text: string,
      }),
      icon: shape({
        id: string,
      }),
    }),
  }),
  highlightedMultimedia: shape({
    id: string.isRequired,
    provider: string.isRequired,
    multimedia_tab: shape({
      icon: shape.isRequired,
      label: shape.isRequired,
    }).isRequired,
    picture_tab: shape({
      icon: shape.isRequired,
      label: shape.isRequired,
    }).isRequired,
  }),
  logo: shape({
    url: shape({}),
    alt: string,
  }),
  clipsConfig: shape({
    autoplay: bool.isRequired,
    hasClips: bool.isRequired,
    size: number.isRequired,
    snackbars: arrayOf(
      shape({
        id: string,
        message: string,
        delay: number,
      }),
    ),
  }),
  onClickBookmark: func,
  openWishlistModalBS: func,
  updateGiftRegistry: func,
  toggleGiftRegistryCheckbox: func,
  closeWishlistModalBS: func,
  fetchBookmark: func,
  bookmark: shape({
    isBookmarked: bool,
    href: string,
    remove: shape({}),
    add: shape({}),
    isFetching: bool,
    themeMode: string,
  }),
  showSnackbar: func.isRequired,
  minHeight: number,
  maxHeight: number,
  app: string,
  overlayAlpha: string,
};
GalleryMobile.defaultProps = {
  accessibility_text: 'carousel',
  bookmark: null,
  clipsConfig: null,
  galleryType: 'default',
  hasClips: false,
  highlightedMultimedia: null,
  logo: null,
  onClickBookmark: () => {},
  openWishlistModalBS: () => {},
  closeWishlistModalBS: () => {},
  fetchBookmark: () => {},
  share: null,
  sizeForBiggerPhoto: null,
  track: null,
  overlayAlpha: null,
};
export default GalleryMobile;
