import APIService from '../../../services/api';
import { QUESTIONS_AI_DATADOG_KEY_CASES } from '../../../services/frontend-statsd/config/allowed-keys';
import { QUESTIONS_AI_REASON_TAG_VALUES } from '../../../services/frontend-statsd/config/allowed-tags';
import { feStatsdHelper } from '../../../utils/frontendStatsHelper';
import { RENDER_MODES } from '../../utils/onePayForAllUtils';
import {
  CART_CONGRATS_UPDATE,
  FETCH_ERROR,
  FETCH_ON_DEMAND_IFRAME,
  ON_DEMAND_IFRAME,
  SHOW_SNACKBAR,
} from '../../vip/types';

const execUpdateComponentsBottomSheet = () => (dispatch, args) => {
  const {
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    selectedShippingOptionId,
    attributes,
    hasDeferredErrors,
  } = args;
  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: selectedShippingOptionId,
    attributes,
    has_deferred_errors: hasDeferredErrors,
    transform_path: true,
    a2c_update_freeshiping_bar: true,
  })
    .then(payload => {
      dispatch({ type: CART_CONGRATS_UPDATE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execShowQuestionsAiModal = (dispatch, args) => {
  const { target, isDismissible, statsdConfig } = args;
  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      has_target: Boolean(target),
    },
  });

  if (target) {
    dispatch({
      type: ON_DEMAND_IFRAME,
      params: {
        show: true,
        src: target,
        isRedirectFlow: false,
        renderMode: RENDER_MODES.CONTAINER,
        isDismissible,
        statsdConfig,
        fallbackConfig: {},
        customNamespace: 'questions-ai',
        noCloseButton: true,
      },
    });
  } else {
    feStatsdHelper({
      key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      statsdConfig,
      additionalTags: {
        reason: QUESTIONS_AI_REASON_TAG_VALUES.EMPTY_TARGET,
        snackbar_type: 'error',
      },
    });

    dispatch({
      type: SHOW_SNACKBAR,
      params: { type: 'error', delay: 3000, called_from: 'vip' },
    });
  }
};

const execShowAddToCartModal = (dispatch, args) => {
  const { data, itemId, labelText, shopsDefaultTrackingData } = args;
  dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: true, labelText, isFetching: false } });
  const urlAddToCartServiceResponse = APIService.addToCartModal(data, itemId)
    .then(response => {
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
      if (response?.data?.shouldOpenModal) {
        dispatch({
          type: ON_DEMAND_IFRAME,
          params: {
            show: response.data.shouldOpenModal,
            src: response.data.target,
            isRedirectFlow: false,
            renderMode: RENDER_MODES.CONTAINER,
            isDismissible: response.data.is_dismissible,
          },
        });
      } else if (response?.data?.target) {
        window.location.href = response.data.target;
      } else if (response?.data?.error_message) {
        dispatch({ type: FETCH_ERROR, error: response.data.error_message });
      }

      return {
        quantitySelected: data.quantity,
        shopsDefaultTrackingData,
        urlAddToCartServiceResponse: response?.data?.target,
      };
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
    });
  return urlAddToCartServiceResponse;
};

export { execUpdateComponentsBottomSheet, execShowQuestionsAiModal, execShowAddToCartModal };
