import get from 'lodash/get';
import {
  FETCH_COMPATS_DELETE_COMPLETE,
  FETCH_COMPATS_DELETE_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_COMPLETE,
  FETCH_COMPATS_SEARCH_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_START,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE,
  FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE,
  FETCH_COMPATS_SEARCH_STICKY_START,
} from '../../vip/types';

const ID_COMPATS_WIDGET = 'compats_widget';

const getterCompatsProductServiceActions = (
  selectedInput,
  updateState,
  stateComponents,
  feature_name,
  fetchStickyComplete,
  fetchComplete,
  type,
  updateWidgetData,
  isMobile,
  getState,
) => {
  const { app, id } = getState();
  const compats_widget_sticky = get(stateComponents, `${feature_name}`);
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);

  const isSticky = type === 'sticky';
  const fetchTypeStart = isSticky ? FETCH_COMPATS_SEARCH_STICKY_START : FETCH_COMPATS_SEARCH_START;
  const fetchTypeComplete = isSticky ? fetchStickyComplete : fetchComplete;
  /* istanbul ignore next */
  const domain_id =
    compats_widget_sticky?.widget_data?.modal?.form?.domain_id ||
    compats_widget_block?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.modal?.form?.domain_id;
  const compats_type =
    compats_widget_sticky?.widget_data?.type ||
    compats_widget_block?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.type;

  const compatsUserSelection = selectedInput.user_selection
    .filter(item => item.selected_value && item.selected_value.id)
    .map(item => `${item.id}:${item.selected_value.id}`)
    .join('|');

  const params = {
    product_id: id,
    app,
    compats_user_selection: compatsUserSelection,
    domain_id,
  };
  return {
    id,
    app,
    domain_id,
    params,
    fetchTypeStart,
    fetchTypeComplete,
    compats_type,
    isMobile,
    updateState,
    updateWidgetData,
    isSticky,
    selectedInput,
  };
};

const getterCompatsProductDesktop = (selectedInput, updateState, feature_name, type, getState) => {
  const { components: stateComponents } = getState();
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);

  const fetchStickyComplete = FETCH_COMPATS_SEARCH_STICKY_COMPLETE;
  const fetchComplete = FETCH_COMPATS_SEARCH_COMPLETE;
  return {
    compats_widget_block,
    fetchStickyComplete,
    fetchComplete,
    selectedInput,
    updateState,
    feature_name,
    type,
    stateComponents,
    getState,
  };
};

const getterCompatsProductWebmobile = (selectedInput, updateState, feature_name, type, getState) => {
  const { components: stateComponents } = getState();
  const compats_widget_block = get(stateComponents, `${feature_name}.components`);
  const fetchStickyComplete = FETCH_COMPATS_SEARCH_STICKY_COMPLETE_MOBILE;
  const fetchComplete = FETCH_COMPATS_SEARCH_COMPLETE_MOBILE;
  return {
    stateComponents,
    compats_widget_block,
    fetchStickyComplete,
    fetchComplete,
    selectedInput,
    updateState,
    feature_name,
    type,
    getState,
  };
};

const getCleanFormCompats = (feature_name, deviceType, updateState, getState) => {
  const { app, id, compats_widget, components: stateComponents } = getState();
  const components = get(stateComponents, `${feature_name}.components`) || get(stateComponents, `${feature_name}`);
  const fetchCompatsDeleteComplete =
    deviceType === 'mobile' ? FETCH_COMPATS_DELETE_COMPLETE_MOBILE : FETCH_COMPATS_DELETE_COMPLETE;
  /* istanbul ignore next */
  const domain_id =
    compats_widget?.widget_data?.modal?.form?.domain_id ||
    components?.widget_data?.modal?.form?.domain_id ||
    components?.find(item => item.id === ID_COMPATS_WIDGET)?.widget_data?.modal?.form?.domain_id;

  const params = {
    product_id: id,
    domain_id,
    app,
  };
  return { id, app, domain_id, params, fetchCompatsDeleteComplete, updateState, feature_name, deviceType };
};

export {
  getterCompatsProductServiceActions,
  getterCompatsProductDesktop,
  getterCompatsProductWebmobile,
  getCleanFormCompats,
};
