import get from 'lodash/get';
import APIService from '../services/api';

import { trackEvent, trackEventWithCustomField, trackEventWithCustomFields, trackPage } from '../lib/tracking';

import { processRequest, requestQueue } from './utils/addToCartRequestQueue';
import { feStatsdHelper } from '../utils/frontendStatsHelper';

import {
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  VPP_SEQUENCER_DATADOG_KEY_CASES,
  QUESTIONS_AI_DATADOG_KEY_PREFIX,
  QUESTIONS_AI_DATADOG_KEY_CASES,
} from '../services/frontend-statsd/config/allowed-keys';

import { OPERATION_FILL, OPERATION_REMOVE } from '../components/installation-service/installation.constants';

import {
  CREATE_QUESTION_START,
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_FROM_AI_COMPLETE,
  CREATE_QUESTION_ERROR,
  DISLIKE_REVIEW_OPTIMISTICALLY,
  DISLIKE_REVIEW_ROLLBACK,
  FETCH_START,
  FETCH_COMPLETE,
  FETCH_ERROR,
  FETCH_ERROR_SNACK,
  FETCH_ERROR_SNACK_HIDE,
  FETCH_ON_SET_QUANTITY_START,
  FETCH_ON_SET_QUANTITY_COMPLETE,
  FETCH_PRODUCT_VARIATIONS_DETAILS,
  LIKE_REVIEW_OPTIMISTICALLY,
  LIKE_REVIEW_ROLLBACK,
  FETCH_SHIPPING_PROMISE_START,
  FETCH_SHIPPING_PROMISE_COMPLETE,
  QUANTITY_SHOW_INPUT_CHANGE,
  ADD_TO_CART_UPDATE_START,
  ADD_TO_CART_UPDATE_COMPLETE,
  ADD_TO_CART_UPDATE_ERROR,
  TOGGLE_BOOKMARKED,
  TOGGLE_BOOKMARK_OPTIMISTICALLY,
  SET_PICKERS_COLLAPSED,
  SET_RECOMMENDATIONS_CONTEXT,
  FETCH_DEFERRED_START,
  FETCH_DEFERRED_SUCCESS,
  FETCH_DEFERRED_ERROR,
  SHOW_QUANTITY_ERROR,
  SHOW_VARIATIONS_ERROR_DESKTOP,
  SHOW_VARIATIONS_ERROR_MOBILE,
  FETCH_ON_COUPON_UPDATE_START,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  ON_COUPON_BUYBOX_OFFERS_UPDATE,
  CART_CONGRATS_UPDATE,
  ON_DEMAND_IFRAME,
  FETCH_ON_DEMAND_IFRAME,
  FETCH_ON_MELIPLUS_UPDATE_START,
  FETCH_ON_MELIPLUS_UPDATE_COMPLETE,
  FETCH_ON_CHANGE_TRADE_IN_START,
  FETCH_ON_CHANGE_TRADE_IN_COMPLETE,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  FETCH_SEQUENCER,
  WISHLIST_SHOW_BOTTOMSHEET_MODAL,
  WISHLIST_CLOSE_BOTTOMSHEET_MODAL,
  UPDATE_GIFT_REGISTRY,
  UPDATE_GIFT_REGISTRY_CHECKBOX,
  TOGGLE_FOLLOW_OPTIMISTICALLY,
  FETCH_ON_SET_FOLLOW_COMPLETE,
  FETCH_QUESTION_AI_START,
  FETCH_QUESTION_AI_COMPLETE,
  FETCH_QUESTION_AI_ERROR,
  INITIAL_QUESION_AI,
  PRELOAD_IFRAME,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_END,
} from './actions';

import {
  newPayloadFetchSequencer,
  updateOnePayForAllTrack,
  redirectOrShowSnackbar,
  getLoadingTimeInterval,
  ACTIONS_SEQUENCER,
  ACTIONS_SEQUENCER_TRACK,
  RENDER_MODES,
} from './utils/onePayForAllUtils';

import { getDefaultErrorMessage } from './utils/getDefaultErrorMessage';
import { arrayToObjectById } from '../reducers/helpers/arrayToObjectById';

import {
  REASON_TAG_VALUES,
  BUTTON_TYPES_TAG_VALUES,
  QUESTIONS_AI_REASON_TAG_VALUES,
  QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES,
  UNKNOWN_VALUE,
} from '../services/frontend-statsd/config/allowed-tags';

import applyCouponSummaryToSelectedOffers from './utils/applyCouponSummaryToSelectedOffers';

import {
  execAddElementToList,
  execRemoveElementFromList,
  execFetchUpdateWishlist,
  execAddBookmark,
  execRemoveBookmark,
} from '../utils/giftRegistry';

import { tracking } from '../pages/questions-ai/events/tracking';
import { showInstallationSnackbar } from './utils/installationsUtils';

const FEEDBACK_STYLE_ERROR = 'ERROR';

const getSelectedAttributes = components => {
  let attributes = get(components, 'variations.selected_attributes', null);

  if (!attributes) {
    attributes = get(components, 'outside_variations.selected_attributes', null);
  }
  if (attributes) {
    attributes = JSON.stringify(attributes).replace(/[{()}]/g, '');
    attributes = attributes.replace(/"/g, '');
  }
  return attributes;
};

const addElementToList = element => (dispatch, getState) => {
  const {
    components: { wishlist_save_button: wishlist },
  } = getState();

  execAddElementToList(element, dispatch, wishlist, SHOW_SNACKBAR, HIDE_SNACKBAR, FETCH_ERROR);
};

const removeElementFromList = element => dispatch => {
  execRemoveElementFromList(element, dispatch, TOGGLE_BOOKMARKED, SHOW_SNACKBAR, FETCH_ERROR);
};

const selectPaymentMethod = offer_type => (dispatch, getState) => {
  const {
    id,
    filters: pdp_filters,
    applied_product_filters,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
    components,
  } = getState();

  const attributes = getSelectedAttributes(components);

  dispatch({
    type: FETCH_START,
    id,
    params: { pdp_filters, applied_product_filters, attributes, quantity },
  });

  APIService.getProduct(id, { offer_type, pdp_filters, attributes, applied_product_filters, quantity })
    .then(payload => {
      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
      trackPage(payload.components.track);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchComponents = (id, params, setIsLoading, transformPayload = null) => (dispatch, getState) => {
  const state = getState();
  const { filters: pdp_filters, applied_product_filters, app } = state;
  setIsLoading(true);
  APIService.getProduct(id, { ...params, app, pdp_filters, applied_product_filters, product_trigger_id: id })
    .then(payload => {
      setIsLoading(false);
      if (typeof transformPayload === 'function') {
        transformPayload(payload, state);
      }

      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
    })
    .catch(e => {
      setIsLoading(false);
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedComponents = (id, params, updateVariationsBox, attrId, setIsLoading) => (dispatch, getState) => {
  const { filters: pdp_filters, applied_product_filters, app } = getState();
  setIsLoading(true);
  APIService.getProductUpdateVariation(id, { ...params, app, pdp_filters, applied_product_filters })
    .then(payload => {
      setIsLoading(false);
      updateVariationsBox(id, attrId, payload);
      dispatch({ type: FETCH_COMPLETE, payload: { id, ...payload } });
      dispatch({ type: SET_RECOMMENDATIONS_CONTEXT, payload: { ...payload } });
    })
    .catch(e => {
      setIsLoading(false);
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedCouponBuyBoxOffers = () => (dispatch, getState) => {
  const {
    id,
    app,
    selected_offer_type: offer_type,
    components: { buy_box_offers },
  } = getState();
  const newbuyBoxOffers = applyCouponSummaryToSelectedOffers(buy_box_offers, { isFetching: true });

  dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, id, payload: { buy_box_offers: newbuyBoxOffers } });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      const newbuy_box_offers = applyCouponSummaryToSelectedOffers(payload.components.buy_box_offers, {
        isFetching: false,
      });
      dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, payload: { buy_box_offers: newbuy_box_offers } });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const fetchUpdatedCoupon = () => (dispatch, getState) => {
  const { id, app, selected_offer_type: offer_type } = getState();

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const hasDoubleAlternatives = components => components?.buy_box_offers?.state === 'VISIBLE';

const mobileUpdateCoupon = () => (dispatch, getState) => {
  const { components } = getState();
  if (hasDoubleAlternatives(components)) {
    return dispatch(fetchUpdatedCouponBuyBoxOffers());
  }
  return dispatch(fetchUpdatedCoupon());
};

const trackIfNecessary = track => {
  if (track) {
    trackEvent(track);
  }
};

const handlePostCouponActivateError = (dispatch, getState, error) => {
  const { siteId } = getState();
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: error?.response?.data?.display_message ?? getDefaultErrorMessage(siteId),
      type: 'error',
      delay: 6000,
      called_from: 'coupon_summary',
    },
  });
};

const postCouponActivate = ({ track, ...params }) => (dispatch, getState) => {
  const {
    app,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
      main_actions: {
        form: { item_id },
      },
    },
  } = getState();

  trackIfNecessary(track);

  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id: item_id });

  APIService.postProductCouponActivate(item_id, { app, quantity, ...params })
    .then(payload => {
      trackIfNecessary(payload?.components?.coupon_summary?.awareness?.track);
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_ERROR });
      handlePostCouponActivateError(dispatch, getState, e);
    });
};

const postCouponActivateBuyBoxOffers = ({ track, ...params }) => (dispatch, getState) => {
  const {
    app,
    components: {
      buy_box_offers,
      selected_offer_type: offer_type,
      available_quantity: {
        picker: { selected: quantity },
      },
      main_actions: {
        form: { item_id },
      },
    },
  } = getState();

  trackIfNecessary(track);

  const updatedBuyBoxOffers = applyCouponSummaryToSelectedOffers(buy_box_offers, { isFetching: true });
  dispatch({ type: ON_COUPON_BUYBOX_OFFERS_UPDATE, payload: { buy_box_offers: updatedBuyBoxOffers } });

  APIService.postProductCouponActivate(item_id, { app, quantity, offer_type, ...params })
    .then(payload => {
      trackIfNecessary(payload?.components?.coupon_summary?.awareness?.track);
      const newCouponSummaryUpdate = { ...payload.components.coupon_summary, isFetching: false };
      const newbuy_box_offers = applyCouponSummaryToSelectedOffers(buy_box_offers, newCouponSummaryUpdate);
      dispatch({
        type: ON_COUPON_BUYBOX_OFFERS_UPDATE,
        payload: { buy_box_offers: newbuy_box_offers },
      });
    })
    .catch(e => {
      const { components } = getState();
      const newbuyBoxOffers = applyCouponSummaryToSelectedOffers(components.buy_box_offers, { isFetching: false });
      dispatch({
        type: ON_COUPON_BUYBOX_OFFERS_UPDATE,
        payload: { buy_box_offers: newbuyBoxOffers },
      });

      handlePostCouponActivateError(dispatch, getState, e);
    });
};

const mobileActivateCoupon = params => (dispatch, getState) => {
  const { components } = getState();
  if (hasDoubleAlternatives(components)) {
    return dispatch(postCouponActivateBuyBoxOffers(params));
  }
  return dispatch(postCouponActivate(params));
};

const fetchDeferredComponent = component => (dispatch, getState) => {
  const { app, id } = getState();
  dispatch({ type: FETCH_DEFERRED_START, id, component });
  APIService.getDeferredComponent({
    id,
    app,
    component_ids: component,
  })
    .then(payload => {
      dispatch({ type: FETCH_DEFERRED_SUCCESS, payload: { id, ...payload } });
    })
    .catch(e => {
      dispatch({ type: FETCH_DEFERRED_ERROR, error: e });
    });
};

const setPickersCollapse = pickerId => dispatch => {
  dispatch({
    type: SET_PICKERS_COLLAPSED,
    payload: {
      pickerId,
    },
  });
};

const fetchErrorSnackHide = () => dispatch => {
  dispatch({ type: FETCH_ERROR_SNACK_HIDE });
};

const fetchVariationDetailsByIds = ids => (dispatch, getState) => {
  const { id, filters: pdp_filters, components } = getState();
  const attributes = getSelectedAttributes(components);
  APIService.getProductVariationsDetails(id, { pdp_filters, attributes, ids })
    .then(payload => dispatch({ type: FETCH_PRODUCT_VARIATIONS_DETAILS, payload }))
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchOnSetQuantity = quantity => (dispatch, getState) => {
  const { id, applied_product_filters, filters: pdp_filters, components, selected_offer_type: offer_type } = getState();
  const attributes = getSelectedAttributes(components);

  dispatch({
    type: FETCH_ON_SET_QUANTITY_START,
    payload: {
      id,
      quantity,
    },
  });

  APIService.getProductOnSetQuantity(id, quantity, { pdp_filters, attributes, applied_product_filters, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_SET_QUANTITY_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchShippingPromise = () => (dispatch, getState) => {
  const state = getState();
  const { id, filters: pdp_filters, selected_offer_type: offer_type } = state;
  const quantity = get(state, 'components.available_quantity.picker.selected', 1);

  dispatch({
    type: FETCH_SHIPPING_PROMISE_START,
    payload: {
      id,
      quantity,
    },
  });

  APIService.getProductDetailsOnShippingPromiseChange(id, quantity, { pdp_filters, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_SHIPPING_PROMISE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const fetchProductOnMeliplusUpdate = updateAction => (dispatch, getState) => {
  const state = getState();
  const { id, platform, app, filters: pdp_filters, vip_filters, selected_offer_type: offer_type, attributes } = state;
  const quantity = get(state, 'components.available_quantity.picker.selected', 1);
  dispatch({
    type: FETCH_ON_MELIPLUS_UPDATE_START,
    payload: {
      id,
      quantity,
    },
  });
  APIService.getProductUpdateMeliplus(id, {
    id,
    attributes,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    update_action: updateAction,
    offer_type,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_MELIPLUS_UPDATE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const onQuantityShowInput = show => dispatch => {
  dispatch({
    type: QUANTITY_SHOW_INPUT_CHANGE,
    payload: {
      show,
    },
  });
};

const onCreateQuestion = ({ itemId, text, track }) => (dispatch, getState) => {
  const { app } = getState();
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      dispatch({ type: CREATE_QUESTION_COMPLETE, payload });
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e.response.data.displayMessage });
    });
};

const onCreateQuestionFromAi = ({ itemId, text, snackbar_message, suggestionTrack, source }) => (
  dispatch,
  getState,
) => {
  const { app, components } = getState();
  const track = get(components, 'questions.track', null);

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      origin: suggestionTrack?.melidata_event?.event_data?.origin || 'form',
      referer_app: app,
      is_post_question_from_webview: false,
      source,
    },
  };

  if (suggestionTrack) {
    const {
      melidata_event: {
        event_data: { origin },
      },
    } = suggestionTrack;

    switch (origin) {
      case 'feedback':
        trackEvent(suggestionTrack);
        break;

      default:
        trackPage(suggestionTrack);
        break;
    }
  }

  dispatch({ type: CREATE_QUESTION_START });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.FLOW_END,
        statsdConfig,
        additionalTags: {
          end_case: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_SUCCESS,
        },
      });

      dispatch({ type: CREATE_QUESTION_FROM_AI_COMPLETE, payload });
      dispatch({ type: INITIAL_QUESION_AI });
      dispatch({
        type: SHOW_SNACKBAR,
        params: { message: snackbar_message, type: 'success', delay: 3000, called_from: 'questions_ai' },
      });
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.ASK_SELLER_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: CREATE_QUESTION_ERROR, error: e.response.data.displayMessage });
    });
};

const fetchUpdatedWishlists = (forceChecked = null) => (dispatch, getState) => {
  const { id, app } = getState();
  execFetchUpdateWishlist(dispatch, id, app, UPDATE_GIFT_REGISTRY, forceChecked, FETCH_ERROR);
};

const toggleGiftRegistryCheckbox = check => dispatch => {
  dispatch({
    type: UPDATE_GIFT_REGISTRY_CHECKBOX,
    payload: {
      check,
    },
  });
};

const toggleBookmark = () => (dispatch, getState) => {
  const STATUS_OK = 'ok';
  const STATUS_ERROR = 'error';
  const STATUS_SUCCESS = 'success';
  const STATUS_NEUTRAL = 'neutral';
  const CALLED_FROM = 'wishlist_save_button';
  const {
    components: {
      bookmark: { is_bookmarked: isBookmarked, item_id: itemId },
      wishlist_save_button,
    },
    csrfToken,
  } = getState();
  dispatch({ type: TOGGLE_BOOKMARK_OPTIMISTICALLY });

  if (isBookmarked) {
    execRemoveBookmark(dispatch, itemId, STATUS_NEUTRAL, STATUS_OK, STATUS_ERROR, CALLED_FROM);
  } else {
    execAddBookmark(
      dispatch,
      itemId,
      csrfToken,
      wishlist_save_button,
      STATUS_SUCCESS,
      STATUS_OK,
      STATUS_ERROR,
      CALLED_FROM,
    );
  }
};

const fetchBookmark = () => dispatch => {
  dispatch({
    type: TOGGLE_BOOKMARKED,
  });
};

const showVariationsError = (deviceType, actionKey) => dispatch => {
  if (deviceType === 'desktop') {
    dispatch({ type: SHOW_VARIATIONS_ERROR_DESKTOP });
  } else {
    dispatch({ type: SHOW_VARIATIONS_ERROR_MOBILE, payload: { actionKey } });
  }
};

const showQuantityError = () => dispatch => {
  dispatch({ type: SHOW_QUANTITY_ERROR });
};

const likeReview = reviewId => dispatch => {
  dispatch({
    type: LIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.likeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: LIKE_REVIEW_ROLLBACK, payload: { reviewId } });
      dispatch({ type: FETCH_ERROR_SNACK_HIDE });
    });
};

const dislikeReview = reviewId => dispatch => {
  dispatch({
    type: DISLIKE_REVIEW_OPTIMISTICALLY,
    payload: {
      reviewId,
    },
  });

  APIService.dislikeReview(reviewId)
    .then(payload => payload)
    .catch(e => {
      try {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: e.response.data.displayMessage } });
      } catch (eMessage) {
        dispatch({ type: FETCH_ERROR_SNACK, error: { message: 'Algo salió mal' } });
      }
      dispatch({ type: DISLIKE_REVIEW_ROLLBACK, payload: { reviewId } });
      dispatch({ type: FETCH_ERROR_SNACK_HIDE });
    });
};

const addToCartUpdate = ({ action, quantity, target, onSuccess, onError }) => (dispatch, getState) => {
  const { app, filters: pdp_filters } = getState();

  const data = {
    action,
    quantity,
    ...target,
  };

  const params = { app, pdp_filters };

  const request = () => {
    dispatch({ type: ADD_TO_CART_UPDATE_START });
    requestQueue.isRequestInProgress = true;

    APIService.addToCartUpdate(data, params)
      .then(payload => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_COMPLETE, payload });
          if (payload.feedback && payload.feedback.style === FEEDBACK_STYLE_ERROR) {
            onError();
          } else {
            onSuccess();
          }
        };
        processRequest(callback);
      })
      .catch(e => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_ERROR, error: e });
          if (onError) {
            onError();
          }
        };
        processRequest(callback);
      });
  };

  if (requestQueue.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

const showSnackbar = ({ message, type, delay, called_from, action, className }) => (dispatch, getState) => {
  const { siteId } = getState();
  const DEFAULT_ERROR_MESSAGE = getDefaultErrorMessage(siteId);
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: message || DEFAULT_ERROR_MESSAGE,
      type,
      delay: delay || 3000,
      called_from: called_from || 'pdp',
      action,
      className,
    },
  });
};

const hideSnackbar = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
};

const updateTradeIn = () => (dispatch, getState) => {
  const { id, selected_offer_type: offer_type } = getState();
  dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_START, id });
  APIService.updateTradeIn(id, { offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_COMPLETE, payload: { id, ...payload } });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const removeTradeIn = tradeInId => (dispatch, getState) => {
  const { id, selected_offer_type: offer_type } = getState();
  dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_START, id });
  APIService.removeTradeIn(id, tradeInId, { offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_TRADE_IN_COMPLETE, payload: { id, ...payload } });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const showAddToCartModal = ({ itemId, labelText }) => (dispatch, getState) => {
  const {
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form },
    },
  } = getState();
  const data = {
    ...form,
    quantity,
    item_id: itemId,
  };
  dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: true, labelText, isFetching: false } });
  APIService.addToCartModal(data, itemId)
    .then(response => {
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
      if (response?.data?.shouldOpenModal) {
        dispatch({
          type: ON_DEMAND_IFRAME,
          params: {
            show: response.data.shouldOpenModal,
            src: response.data.target,
            isRedirectFlow: false,
            renderMode: RENDER_MODES.CONTAINER,
            isDismissible: response.data.is_dismissible,
          },
        });
      } else if (response?.data?.target) {
        window.location.href = response.data.target;
      } else if (response?.data?.error_message) {
        dispatch({ type: FETCH_ERROR, error: response.data.error_message });
      }
    })
    .catch(e => {
      dispatch({ type: FETCH_ON_DEMAND_IFRAME, params: { loading: false, labelText, isFetching: false } });
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const showQuestionsAiModal = ({ target, isDismissible, disabled, called_from, source }) => (dispatch, getState) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_id: `${called_from}.${BUTTON_TYPES_TAG_VALUES.ASK_QUESTIONS_AI}`,
      referer_app: app,
      is_disabled: Boolean(disabled),
      called_from,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      has_target: Boolean(target),
    },
  });

  if (target) {
    dispatch({
      type: ON_DEMAND_IFRAME,
      params: {
        show: true,
        src: target,
        isRedirectFlow: false,
        renderMode: RENDER_MODES.CONTAINER,
        isDismissible,
        statsdConfig,
        fallbackConfig: {},
        customNamespace: 'questions-ai',
        noCloseButton: true,
      },
    });
  } else {
    feStatsdHelper({
      key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
      statsdConfig,
      additionalTags: {
        reason: QUESTIONS_AI_REASON_TAG_VALUES.EMPTY_TARGET,
        snackbar_type: 'error',
      },
    });

    dispatch({
      type: SHOW_SNACKBAR,
      params: { type: 'error', delay: 3000, called_from: 'pdp' },
    });
  }
};

const showPaymentsSplitModal = ({ target }) => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: true,
      src: `${target}&parent_origin=${window?.location?.origin}`,
      isRedirectFlow: false,
      renderMode: RENDER_MODES.CONTAINER,
      isDismissible: true,
      fallbackConfig: {},
      customNamespace: 'split-payments',
    },
  });
};

const onDemandIframeClose = () => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params: {
      show: false,
      src: null,
      isRedirectFlow: false,
      fallbackConfig: null,
      statsdConfig: null,
      sequencerTrack: null,
      customNamespace: null,
      noCloseButton: false,
    },
    isFetching: false,
  });
};

const onDemandIframeUpdate = params => dispatch => {
  dispatch({
    type: ON_DEMAND_IFRAME,
    params,
  });
};

const updateComponentsBottomSheet = () => (dispatch, getState) => {
  const {
    id,
    applied_product_filters,
    filters: pdp_filters,
    selected_offer_type: offer_type,
    components,
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
    },
  } = getState();
  const attributes = getSelectedAttributes(components);
  APIService.getProductOnSetQuantity(id, quantity, {
    pdp_filters,
    attributes,
    applied_product_filters,
    offer_type,
    a2c_update_freeshiping_bar: true,
  })
    .then(payload => {
      dispatch({ type: CART_CONGRATS_UPDATE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const triggerBuyNowOnePayForAll = actionKey => (dispatch, getState) => {
  const {
    components: {
      available_quantity: { picker: { selected: quantity } = { selected: null } },
      main_actions: { form, actions },
    },
    siteId,
  } = getState();

  dispatch({
    type: FETCH_SEQUENCER,
    payload: newPayloadFetchSequencer(actions, true, actionKey),
  });

  APIService.buyNowOnePayForAll({ ...form, quantity })
    .then(response => {
      window.location.href = response.target;
    })
    .catch(e => {
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e.response.data.displayMessage ?? getDefaultErrorMessage(siteId),
          type: 'error',
          delay: 3000,
          called_from: 'pdp',
        },
      });
    });
};

/* eslint-disable complexity */
const triggerSequencer = actionKey => (dispatch, getState) => {
  const {
    app,
    components: {
      main_actions: { actions },
    },
    preload,
    siteId,
  } = getState();

  const action = actions.find(act => actionKey === act.label.text);

  const {
    fallback,
    target,
    disabled,
    display_instantly,
    trigger_action: triggerAction,
    intervention_id: interventionId,
    track,
  } = action;

  if (!display_instantly) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, true, actionKey),
    });
  }

  let fallbackUrl = fallback;

  // Datadog config
  const statsdConfig = {
    keyPrefix: VPP_SEQUENCER_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: triggerAction || UNKNOWN_VALUE,
      referer_app: app,
      is_disabled: Boolean(disabled),
      intervention_id: interventionId || UNKNOWN_VALUE,
    },
  };

  // DATADOG - action click
  feStatsdHelper({
    key: VPP_SEQUENCER_DATADOG_KEY_CASES.ACTION_CLICK,
    additionalTags: {
      main_action_target: Boolean(target),
      main_action_fallback: Boolean(fallback),
      main_action_empty: !target && !fallback,
    },
    additionalInfo: { action },
    shouldLogMessage: !target || !fallback,
    statsdConfig,
  });

  if (!target) {
    dispatch({
      type: FETCH_SEQUENCER,
      payload: newPayloadFetchSequencer(actions, false, actionKey),
    });
    redirectOrShowSnackbar({
      dispatch,
      fallbackToRedirect: null,
      reason: REASON_TAG_VALUES.NO_MAIN_ACTION_TARGET,
      siteId,
      statsdConfig,
      trackData: track,
    });
    return;
  }

  if (display_instantly) {
    if (preload?.isIframeLoaded) {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: true,
        },
        statsdConfig,
      });
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: true,
          fallback: fallbackUrl,
          statsdConfig,
        },
      });
    } else {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.DISPLAY_INSTANTLY,
        additionalTags: {
          preload_ready: false,
        },
        statsdConfig,
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, true, actionKey),
      });
    }
  }

  const params = Object.fromEntries(new URLSearchParams(target.split('?')[1]));

  params.is_preload_shown = Boolean(preload?.isIframeLoaded);

  APIService.getSequencerOnePayForAll(params)
    .then(response => {
      const knownCases = [
        ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD,
        ACTIONS_SEQUENCER.BOTTOM_SHEET,
        ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD,
        ACTIONS_SEQUENCER.LANDING,
        ACTIONS_SEQUENCER.SNACKBAR,
        ACTIONS_SEQUENCER.NAVIGATE,
      ];
      const actionType = response?.actions?.action_type ?? '';
      const targetUrl = response?.actions?.target;
      const responseFallback = response?.actions?.fallback;
      if (responseFallback) {
        fallbackUrl = responseFallback;
      }
      const fallbackConfig = {
        fallbackUrl,
        fallbackMessage: response?.actions?.fallback_message,
        timeoutInitialLoad: response?.actions?.timeout,
        timeoutWebviewMessage: response?.actions?.timeout_webview_message,
      };
      const isDismissible = response?.actions?.bottom_sheet?.is_dismissible;
      const snackbarMessage = response?.actions?.snackbar?.message;
      const snackbarType = response?.actions?.snackbar?.type;
      const sequencerTrack = response?.actions?.track;
      const infoUpdate = response?.actions?.info_update || false;

      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });

      if (sequencerTrack) {
        trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.RESPONSE));
      }

      // DATADOG - get response
      // TODO-PRELOAD-REFACTOR: Validar este punto cuando queremos logear
      // Es importante entender que no queremos afectar otros dash
      // Y que estas respuestas podrian no tener target por ejemplo ya que serîa un update
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.GET_RESPONSE,
        additionalTags: {
          response_target: Boolean(targetUrl),
          response_fallback: Boolean(responseFallback),
          response_empty: !targetUrl && !responseFallback,
          response_case: actionType,
        },
        additionalInfo: { response, params },
        shouldLogMessage: !targetUrl || !responseFallback || !knownCases.includes(actionType),
        statsdConfig,
      });

      switch (actionType) {
        // NOTA: bottom_sheet_with_preload o landing_with_preload "hacen lo mismo"
        // El modo de visualización es responsabilidad / tarea de la webview
        // Pues nosotros mostramos un iframe del 100% y la misma webview decide que dibujar dentro
        case ACTIONS_SEQUENCER.BOTTOM_SHEET_WITH_PRELOAD:
        case ACTIONS_SEQUENCER.LANDING_WITH_PRELOAD:
          if (!preload?.isIframeLoaded) {
            const loadingTime = getLoadingTimeInterval({
              nowTime: Date.now(),
              loadingStartTime: preload?.loadingStartTime,
            });
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_WEBVIEW_PRELOADED,
              tags: { loading_time: loadingTime || UNKNOWN_VALUE },
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: true,
              fallback: fallbackUrl,
              infoUpdate,
              statsdConfig,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.BOTTOM_SHEET:
        case ACTIONS_SEQUENCER.LANDING:
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET
                  ? REASON_TAG_VALUES.NO_BOTTOMSHEET_TARGET
                  : REASON_TAG_VALUES.NO_LANDING_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.LOADING));
          }
          dispatch({
            type: ON_DEMAND_IFRAME,
            params: {
              show: true,
              src: targetUrl,
              isRedirectFlow: false,
              renderMode:
                actionType === ACTIONS_SEQUENCER.BOTTOM_SHEET ? RENDER_MODES.CONTAINER : RENDER_MODES.FULLSCREEN,
              fallbackConfig,
              statsdConfig,
              isDismissible,
              sequencerTrack,
            },
          });
          break;
        case ACTIONS_SEQUENCER.SNACKBAR:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
              snackbar_type: snackbarType || 'error',
            },
          });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: snackbarMessage ?? getDefaultErrorMessage(siteId),
              type: snackbarType ? snackbarType.toLowerCase() : 'error',
              delay: 3000,
              called_from: 'pdp',
            },
          });
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.SNACKBAR));
          }
          break;
        case ACTIONS_SEQUENCER.NAVIGATE:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          if (!targetUrl) {
            redirectOrShowSnackbar({
              dispatch,
              siteId,
              fallbackToRedirect: fallbackUrl,
              reason: REASON_TAG_VALUES.NO_NAVIGATE_TARGET,
              message: fallbackConfig.fallbackMessage,
              statsdConfig,
              trackData: sequencerTrack,
            });
            break;
          }
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.NAVIGATE));
          }
          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
            statsdConfig,
            additionalTags: {
              end_case: actionType,
            },
          });
          window.location.href = targetUrl;
          break;
        default:
          dispatch({
            type: PRELOAD_IFRAME,
            params: {
              show: false,
            },
          });
          redirectOrShowSnackbar({
            dispatch,
            siteId,
            fallbackToRedirect: null,
            reason: REASON_TAG_VALUES.UNKNOWN_CASE,
            message: fallbackConfig.fallbackMessage,
            statsdConfig,
            trackData: sequencerTrack,
          });
          break;
      }
    })
    .catch(error => {
      dispatch({
        type: PRELOAD_IFRAME,
        params: {
          show: false,
        },
      });
      dispatch({
        type: FETCH_SEQUENCER,
        payload: newPayloadFetchSequencer(actions, false, actionKey),
      });
      redirectOrShowSnackbar({
        dispatch,
        fallbackToRedirect: fallbackUrl,
        reason: REASON_TAG_VALUES.CATCH_SEQUENCER,
        additionalInfo: { error, params },
        shouldLogMessage: true,
        siteId,
        statsdConfig,
        trackData: track,
      });
    });
};
/* eslint-enable complexity */

const preloadIframeLoaded = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { isIframeLoaded: true } });
};

const preloadIframeClose = () => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params: { show: false } });
};

const preloadIframeUpdate = params => dispatch => {
  dispatch({ type: PRELOAD_IFRAME, params });
};

const toggleFollowSeller = () => (dispatch, getState) => {
  const {
    app,
    components: { seller_data: sellerData },
    siteId,
  } = getState();
  const { followers } = sellerData.components.find(el => el.id === 'seller_header');
  const params = new URLSearchParams(followers.query_params);
  params.append('app', app);
  params.append('site_id', siteId);

  dispatch({ type: TOGGLE_FOLLOW_OPTIMISTICALLY });
  if (followers.cta_status === 'do_follow') {
    trackEvent(followers.cta_do_follow.track_event);
    APIService.followSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  } else {
    trackEvent(followers.cta_stop_follow.track_event);
    APIService.unfollowSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  }
};

const openWishlistModalBS = () => dispatch => {
  dispatch({ type: HIDE_SNACKBAR });
  dispatch({ type: WISHLIST_SHOW_BOTTOMSHEET_MODAL });
};

const closeWishlistModalBS = isBookmarked => dispatch => {
  dispatch({ type: WISHLIST_CLOSE_BOTTOMSHEET_MODAL, payload: { isBookmarked } });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  try {
    APIService.redirectToLogin(itemId, loginType, featureName);
  } catch (error) {
    throw new Error(error);
  }
};

const saveFrontendStatsd = data => (dispatch, getState) => {
  const { app: referer_app } = getState();

  if (!data) {
    return;
  }

  data.tags.referer_app = referer_app;

  APIService.saveFrontendStatsd(data);
};

const postQuestionFromAi = ({ productId, itemId, text, track, attributes, quantity, source }) => (
  dispatch,
  getState,
) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      referer_app: app,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.SEND_QUESTION,
    },
  });

  dispatch({ type: FETCH_QUESTION_AI_START });

  if (track) {
    tracking({ track });
  }

  APIService.postQuestion(itemId, { productId, text, app, attributes, quantity })
    .then(payload => {
      if (payload) {
        if (payload.track) {
          tracking({ track: payload.track });
        }

        feStatsdHelper({
          key: QUESTIONS_AI_DATADOG_KEY_CASES.SUGGESTION_RESPONSE,
          statsdConfig,
          additionalTags: {
            classification: payload.track?.melidata_event?.event_data?.classification,
          },
        });

        if (payload.make_action) {
          dispatch(
            onCreateQuestionFromAi({
              itemId,
              text: payload.make_action.config.user_question,
              snackbar_message: payload.make_action.snackbar_message,
              source,
            }),
          );
        } else {
          dispatch({ type: FETCH_QUESTION_AI_COMPLETE, payload: arrayToObjectById(payload.components) });
        }
      }
    })
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.SUGGESTION_RESPONSE_NOT_LOADED,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_QUESTION_AI_ERROR, error: e });
    });
};

const isUseful = ({ itemId, useful, id, response, track, source }) => (dispatch, getState) => {
  const { app } = getState();

  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.FEEDBACK,
      source,
    },
  };

  // DATADOG - ACTION CLICK
  feStatsdHelper({
    key: QUESTIONS_AI_DATADOG_KEY_CASES.ACTION_CLICK,
    statsdConfig,
    additionalTags: {
      like: Boolean(useful),
    },
  });

  if (track) {
    tracking({ track });
  }

  APIService.likeDislikeQuestionsAi(itemId, { useful, id, response, app })
    .then(() => {})
    .catch(e => {
      feStatsdHelper({
        key: QUESTIONS_AI_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        statsdConfig,
        additionalTags: {
          reason: QUESTIONS_AI_REASON_TAG_VALUES.FEEDBACK_ERROR,
          snackbar_type: 'error',
        },
        shouldLogMessage: e,
        additionalInfo: e,
      });
      dispatch({ type: FETCH_ERROR, error: e });
    });
};
const updateInstallation = agency => (dispatch, getState) => {
  const {
    filters: pdp_filters,
    applied_product_filters,
    app,
    id,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
  } = getState();

  const params = {
    product_id: id,
    app,
    ...agency,
    quantity,
    operation: OPERATION_FILL,
    pdp_filters,
    applied_product_filters,
  };
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};
const removeInstallation = () => (dispatch, getState) => {
  const {
    filters: pdp_filters,
    applied_product_filters,
    app,
    id,
    components: {
      available_quantity: {
        picker: { selected: quantity },
      },
    },
  } = getState();
  const params = {
    product_id: id,
    app,
    operation: OPERATION_REMOVE,
    quantity,
    pdp_filters,
    applied_product_filters,
  };
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};

export {
  dislikeReview,
  fetchErrorSnackHide,
  fetchComponents,
  fetchUpdatedCoupon,
  mobileUpdateCoupon,
  fetchUpdatedCouponBuyBoxOffers,
  postCouponActivate,
  postCouponActivateBuyBoxOffers,
  mobileActivateCoupon,
  fetchUpdatedComponents,
  selectPaymentMethod,
  setPickersCollapse,
  fetchShippingPromise,
  fetchProductOnMeliplusUpdate,
  fetchVariationDetailsByIds,
  fetchOnSetQuantity,
  likeReview,
  onCreateQuestion,
  onQuantityShowInput,
  toggleBookmark,
  fetchBookmark,
  fetchDeferredComponent,
  showQuantityError,
  showVariationsError,
  addToCartUpdate,
  showSnackbar,
  hideSnackbar,
  updateTradeIn,
  removeTradeIn,
  showAddToCartModal,
  showQuestionsAiModal,
  showPaymentsSplitModal,
  onCreateQuestionFromAi,
  triggerBuyNowOnePayForAll,
  triggerSequencer,
  onDemandIframeClose,
  onDemandIframeUpdate,
  preloadIframeLoaded,
  preloadIframeClose,
  preloadIframeUpdate,
  openWishlistModalBS,
  closeWishlistModalBS,
  redirectToLogin,
  updateComponentsBottomSheet,
  saveFrontendStatsd,
  addElementToList,
  removeElementFromList,
  fetchUpdatedWishlists,
  toggleFollowSeller,
  postQuestionFromAi,
  isUseful,
  toggleGiftRegistryCheckbox,
  updateInstallation,
  removeInstallation,
};
