import React, { useState, useEffect, useRef, useContext } from 'react';
import { bool, string, func, shape, number } from 'prop-types';
import { Modal } from '@andes/modal';
import { VisuallyHidden } from '@andes/common';
import classNames from 'classnames';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import object from '../../lib/global';
import { trackEvent } from '../../lib/tracking';
import { stringIncludes } from '../../lib/includes';
import Iframe from '../iframe';
import targetHostnameValidator from '../../utils/targetHostnameValidator';
import { eventTypes } from './utils/constants';
import { VPP_SEQUENCER_DATADOG_KEY_CASES } from '../../services/frontend-statsd/config/allowed-keys';
import { REASON_TAG_VALUES } from '../../services/frontend-statsd/config/allowed-tags';
import { feStatsdHelper } from '../../utils/frontendStatsHelper';
import { updateOnePayForAllTrack, RENDER_MODES, ACTIONS_SEQUENCER_TRACK } from '../../actions/utils/onePayForAllUtils';
import StaticPropsContext from '../context/static-props';

const {
  // TODO-PRELOAD-REFACTOR: REMOVE THIS:
  DEPRECATED_MSG_LOADED,
  DEPRECATED_MSG_REDIRECT,
  DEPRECATED_MSG_NAVIGATE,
  DEPRECATED_MSG_CLOSE,
  DEPRECATED_MSG_SNACKBAR,
  // TODO-PRELOAD-REFACTOR: LEAVE THIS:
  MSG_LOADED,
  MSG_CLOSE,
  MSG_CLOSABLE,
  MSG_SNACKBAR,
  MSG_NAVIGATE,
  MSG_REDIRECT,
  MSG_ASK_SELLER,
  MSG_SCROLL_TO_ELEMENT,
} = eventTypes;

const DEFAULT_TIMEOUT_INITIAL_LOAD = 10000;
const DEFAULT_TIMEOUT_WEBVIEW_MESSAGE = 5000;

const OnDemandIframe = ({
  id,
  src,
  isRedirectFlow,
  title,
  show,
  renderMode,
  noScrolling,
  fallbackConfig,
  statsdConfig,
  isDismissible,
  showSnackbar,
  onDemandIframeClose,
  onDemandIframeUpdate,
  updateComponentsBottomSheet,
  onCloseVariations, // TODO-FRAMES Este metodo deberia dejar de existir cuando se apaguen pickers viejos + modal variaciones
  onCreateQuestionFromAi,
  showVariationsError,
  sequencerTrack,
  customNamespace,
  noCloseButton,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    // Refs
    const iframeFallbackTimeout = useRef(null);
    const iframeRedirectFlow = useRef(null);
    const iframeRenderMode = useRef(null);
    // States
    const [loaded, setLoaded] = useState(false);
    const [onCloseTracks, setOnCloseTracks] = useState(null);
    const [titleModalIframe, setTitleModalIframe] = useState(null);
    // Const
    const { fallbackUrl, fallbackMessage, timeoutInitialLoad, timeoutWebviewMessage } = fallbackConfig || {};
    const { deviceType } = useContext(StaticPropsContext);

    const modalClassName = classNames('on-demand-iframe', {
      'on-demand-iframe--desktop': deviceType === 'desktop',
      'on-demand-iframe--mobile': deviceType === 'mobile',
      'on-demand-iframe--mobile__container': deviceType === 'mobile' && renderMode === RENDER_MODES.CONTAINER,
      'on-demand-iframe--mobile__fullscreen': deviceType === 'mobile' && renderMode === RENDER_MODES.FULLSCREEN,
      'on-demand-iframe--no__close__button': noCloseButton,
      [`on-demand-iframe--${customNamespace}`]: customNamespace,
    });

    // Helpers
    const getModalType = () => {
      if (renderMode === RENDER_MODES.FULLSCREEN) {
        return 'full';
      }
      return deviceType === 'mobile' ? 'card' : 'large';
    };

    const showSnackbarAndSendMetric = ({ message, additionalTags = {}, additionalInfo, shouldLogMessage }) => {
      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.SHOW_SNACKBAR,
        additionalTags: {
          snackbar_type: 'error',
          ...additionalTags,
        },
        additionalInfo,
        shouldLogMessage,
        statsdConfig,
      });
      if (showSnackbar) {
        showSnackbar({ message, type: 'error' });
      }
    };

    const goToUrl = ({ url, target, newIsDismissible, newFallbackConfig, newRenderMode, noLoadedCheck }) => {
      const validUrl = url && targetHostnameValidator({ target: url, currentLocation: window.location });
      if (validUrl) {
        if (target === 'parent') {
          window.location.href = url;
        }
        if (target === 'modal' && onDemandIframeUpdate) {
          if (!noLoadedCheck) {
            setLoaded(false);
          }
          setOnCloseTracks(null); // Cleans the tracks -this is set again during the load event-

          // Agregamos un hash para asegurarnos que si el usuario hace back y quiere navegar nuevamente al mismo link
          // La 2da navegación sea a una url nueva, si no lo hacemos nunca navega y queda encerrado en un spinner
          const symbol = url && stringIncludes(url, '?') ? '&' : '?';
          const navigationTimeStampHash = Date.now()
            .toString()
            .slice(-5);
          const dataUpdateIframe = {
            src: `${url}${symbol}navhash=${navigationTimeStampHash}`,
            isRedirectFlow: true,
            fallbackConfig: noLoadedCheck ? null : newFallbackConfig,
            isDismissible: newIsDismissible,
          };

          if (newRenderMode) {
            dataUpdateIframe.renderMode = newRenderMode;
          }

          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack, ACTIONS_SEQUENCER_TRACK.NAVIGATE_LOADING));
          }

          feStatsdHelper({
            key: VPP_SEQUENCER_DATADOG_KEY_CASES.WEBVIEW_REDIRECT,
            statsdConfig,
          });

          onDemandIframeUpdate(dataUpdateIframe);
        }
      } else {
        showSnackbarAndSendMetric({
          additionalTags: {
            reason: REASON_TAG_VALUES.INVALID_REDIRECT_URL,
          },
          additionalInfo: { url, target },
          shouldLogMessage: true,
        });
      }
    };

    const updateCartIcon = () => {
      object.freya?.emit('cart:refresh');
    };

    const onCloseModal = () => {
      // TODO-FRAMES Este metodo deberia dejar de existir cuando se apaguen pickers viejos + modal variaciones
      if (onCloseVariations) {
        onCloseVariations();
      }
      if (onDemandIframeClose) {
        onDemandIframeClose();
      }
      if (onCloseTracks) {
        trackEvent(onCloseTracks);
        setOnCloseTracks(null);
      }
    };

    // Event handlers
    const handleLoadedEvent = (data = {}) => {
      const {
        trackData = null,
        refreshCartIcon = false,
        shouldUpdateComponents = false,
        disableClose = false,
        titleModal = null,
      } = data;

      // Fallback redirect && loaded
      clearTimeout(iframeFallbackTimeout.current);
      setTitleModalIframe(titleModal);
      setLoaded(true);

      // TODO-FRAMES: BORRAR ESTE IF ## Esto lo dejamos hasta que BE nos mande el isDismissible=false para GRD y mientras tanto no perder la funcionalidad.
      if (disableClose) {
        onDemandIframeUpdate({ isDismissible: false });
      }

      // Tracks
      if (trackData) {
        setOnCloseTracks({
          melidata_event: { ...trackData },
        });
      }

      if (sequencerTrack) {
        trackEvent(
          updateOnePayForAllTrack(
            sequencerTrack,
            iframeRedirectFlow.current ? ACTIONS_SEQUENCER_TRACK.NAVIGATE_LOADED : ACTIONS_SEQUENCER_TRACK.LOADED,
          ),
        );
      }

      feStatsdHelper({
        key: VPP_SEQUENCER_DATADOG_KEY_CASES.FLOW_END,
        additionalTags: {
          end_case: iframeRedirectFlow.current ? `${iframeRenderMode.current}_redirect` : iframeRenderMode.current,
        },
        statsdConfig,
      });

      // Updates
      if (refreshCartIcon) {
        updateCartIcon();
      }
      if (shouldUpdateComponents && updateComponentsBottomSheet) {
        updateComponentsBottomSheet();
      }
    };

    const handleNavigateEvent = (data = {}) => {
      const {
        url,
        fallback,
        fallback_message,
        timeout,
        timeout_webview_message,
        no_loaded_check,
        is_dismissible,
        render_mode,
      } = data;
      goToUrl({
        url,
        target: 'modal',
        newRenderMode: render_mode,
        newFallbackConfig: {
          ...fallbackConfig,
          fallbackUrl: fallback,
          fallbackMessage: fallback_message,
          timeoutInitialLoad: timeout,
          timeoutWebviewMessage: timeout_webview_message,
        },
        noLoadedCheck: no_loaded_check,
        newIsDismissible: is_dismissible,
      });
    };

    const handleSnackbarEvent = (data = {}) => {
      const { snackbar, close } = data;
      if (snackbar && showSnackbar) {
        const { message, type, delay, called_from, action } = snackbar;
        if (close) {
          onCloseModal();
        }
        if (action) {
          action.onClick = () => goToUrl({ url: action.redirectUrl, target: 'parent' });
        }
        showSnackbar({ message, type, delay, called_from, action });
      }
    };

    const handleAskSellerEvent = (data = {}) => {
      const { itemId, question, snackbar_message, track } = data;
      if (question && itemId && onCreateQuestionFromAi) {
        onCloseModal();
        onCreateQuestionFromAi({
          itemId,
          text: question,
          snackbar_message,
          suggestionTrack: track,
          source: 'webview',
        });
      }
    };

    const handleScrollToElementEvent = (data = {}) => {
      const { selector, shouldRaiseErrors, actionKey } = data;

      if (selector) {
        const element = document.querySelector(selector);

        onCloseModal();
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        if (shouldRaiseErrors) {
          showVariationsError(deviceType, actionKey);
        }
      }
    };

    const handleMessage = e => {
      if (show) {
        if (e?.data?.type) {
          switch (e.data.type) {
            case DEPRECATED_MSG_LOADED:
            case MSG_LOADED:
              handleLoadedEvent(e.data.data);
              break;
            case DEPRECATED_MSG_CLOSE:
            case MSG_CLOSE:
              onCloseModal();
              break;
            case MSG_CLOSABLE:
              onDemandIframeUpdate({ isDismissible: e.data.enabled });
              break;
            case DEPRECATED_MSG_SNACKBAR:
            case MSG_SNACKBAR:
              handleSnackbarEvent(e.data);
              break;
            case DEPRECATED_MSG_NAVIGATE:
            case MSG_NAVIGATE:
              handleNavigateEvent(e.data);
              break;
            case DEPRECATED_MSG_REDIRECT:
            case MSG_REDIRECT:
              goToUrl({ url: e.data.url, target: 'parent' });
              break;
            case MSG_ASK_SELLER:
              handleAskSellerEvent(e.data);
              break;
            case MSG_SCROLL_TO_ELEMENT:
              handleScrollToElementEvent(e.data);
              break;
            default:
              break;
          }
        }
      }
    };

    const handleIframeOnLoad = () => {
      clearTimeout(iframeFallbackTimeout.current);
      if (fallbackConfig) {
        iframeFallbackTimeout.current = setTimeout(() => {
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack));
          }
          if (fallbackUrl && targetHostnameValidator({ target: fallbackUrl, currentLocation: window.location })) {
            feStatsdHelper({
              key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
              additionalTags: {
                reason: REASON_TAG_VALUES.TIMEOUT_WEBVIEW_MESSAGE,
                flow_redirect: Boolean(iframeRedirectFlow.current),
              },
              statsdConfig,
            });
            window.location.href = fallbackUrl;
          } else {
            showSnackbarAndSendMetric({
              message: fallbackMessage?.message,
              additionalTags: {
                reason: REASON_TAG_VALUES.TIMEOUT_WEBVIEW_MESSAGE,
                flow_redirect: Boolean(iframeRedirectFlow.current),
              },
            });
            onCloseModal();
          }
        }, timeoutWebviewMessage || DEFAULT_TIMEOUT_WEBVIEW_MESSAGE);
      }
    };

    // Effects
    useEffect(() => {
      if (src && fallbackConfig) {
        iframeFallbackTimeout.current = setTimeout(() => {
          if (sequencerTrack) {
            trackEvent(updateOnePayForAllTrack(sequencerTrack));
          }
          if (fallbackUrl && targetHostnameValidator({ target: fallbackUrl, currentLocation: window.location })) {
            feStatsdHelper({
              key: VPP_SEQUENCER_DATADOG_KEY_CASES.REDIRECT_TO_FALLBACK,
              additionalTags: {
                reason: REASON_TAG_VALUES.TIMEOUT_INITIAL_LOAD,
                flow_redirect: Boolean(iframeRedirectFlow.current),
              },
              statsdConfig,
            });
            window.location.href = fallbackUrl;
          } else {
            showSnackbarAndSendMetric({
              message: fallbackMessage?.message,
              additionalTags: {
                reason: REASON_TAG_VALUES.TIMEOUT_INITIAL_LOAD,
                flow_redirect: Boolean(iframeRedirectFlow.current),
              },
            });
            onCloseModal();
          }
        }, timeoutInitialLoad || DEFAULT_TIMEOUT_INITIAL_LOAD);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    useEffect(() => {
      if (!show) {
        setLoaded(false);
      }

      if (onCloseVariations && show) {
        document.querySelector('.ui-pdp-backdrop-modal')?.setAttribute('style', 'height:0px');
        document.querySelectorAll('.andes-modal__overlay')[0]?.setAttribute('style', 'height:0px');
      }
      object.addEventListener('message', handleMessage);
      return () => {
        object.removeEventListener('message', handleMessage);
        clearTimeout(iframeFallbackTimeout.current); // Cuando se cierra la BS matamos el timeout actual ( ** Lo hacemos para asegurarnos, aunque ya deberia estar clear)
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    // This updates the references to ensure that the metrics are sent with the correct value (without depending on renders or race conditions)
    useEffect(() => {
      iframeRenderMode.current = renderMode === RENDER_MODES.CONTAINER ? 'bottom_sheet' : 'landing';
      iframeRedirectFlow.current = Boolean(isRedirectFlow);
    }, [renderMode, isRedirectFlow]);

    return (
      <Modal
        open={show}
        id={id}
        onClose={onCloseModal}
        type={getModalType()}
        title={titleModalIframe ? <VisuallyHidden>{titleModalIframe}</VisuallyHidden> : title || ' '}
        className={modalClassName}
        closable={loaded && isDismissible !== false}
      >
        <Iframe
          title="iframe"
          data-testid="iframe-element"
          src={src}
          showSpinner
          scrolling={noScrolling ? 'no' : 'auto'}
          forceSpinner={!loaded}
          spinnerClassname="center-spinner"
          onLoad={handleIframeOnLoad}
          onMessage={
            renderMode === RENDER_MODES.FULLSCREEN
              ? () => {}
              : onIframeModalResize(
                  'vpp:on-demand-iframe:resize',
                  'height',
                  '.ui-pdp-iframe-modal',
                  '.andes-modal__header',
                )
          }
        />
      </Modal>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

OnDemandIframe.propTypes = {
  id: string,
  src: string.isRequired,
  title: string,
  show: bool,
  isRedirectFlow: bool,
  renderMode: string.isRequired,
  noScrolling: string,
  customNamespace: string,
  fallbackConfig: shape({
    fallbackUrl: string,
    fallbackMessage: shape({
      message: string,
      description: string,
      owner: string,
      errorValue: string,
    }),
    timeoutInitialLoad: number,
    timeoutWebviewMessage: number,
  }),
  statsdConfig: shape({
    keyPrefix: string,
    baseTags: shape({}),
  }),
  isDismissible: bool,
  onDemandIframeClose: func.isRequired,
  onDemandIframeUpdate: func.isRequired,
  updateComponentsBottomSheet: func,
  onCloseVariations: func,
  onCreateQuestionFromAi: func,
  showVariationsError: func,
  showSnackbar: func,
  sequencerTrack: shape({
    melidata_event: shape({}),
  }),
  noCloseButton: bool,
  runCatchErrorBoundary: func,
};

OnDemandIframe.defaultProps = {
  id: '',
  title: '',
  renderMode: RENDER_MODES.CONTAINER,
  show: false,
  fallbackConfig: null,
  statsdConfig: null,
  isDismissible: true,
  updateComponentsBottomSheet: null,
  onCloseVariations: null,
  onCreateQuestionFromAi: null,
  showSnackbar: null,
  showVariationsError: null,
  noScrolling: false,
  noCloseButton: false,
  runCatchErrorBoundary: () => {},
};

export default OnDemandIframe;
