const getGroupKey = product => `${product?.track?.melidata_event?.event_data?.picker_id}-${!!product.picture}`;

const getProductDecoration = (product, selectedProduct, DECORATION_SELECTED, DECORATION_NONE) => {
  const selectedGroupKey = selectedProduct ? getGroupKey(selectedProduct) : null;
  const currentGroupKey = getGroupKey(product);

  if (selectedProduct?.attribute_id === product?.attribute_id && selectedProduct?.id === product?.id) {
    return DECORATION_SELECTED;
  }

  if (selectedProduct && selectedGroupKey === currentGroupKey && product.selected) {
    return DECORATION_NONE;
  }

  return product.decoration_type;
};

export default getProductDecoration;
